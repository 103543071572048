import Image from 'next/image';
import { useState } from 'react';
import styles from './Video.module.css';

const Video = ({ slice }) => {
  const [isplayClicked, setIsPlayClicked] = useState(false);
  const { constrainWidth } = slice.primary;

  const playVideoHandler = () => {
    setIsPlayClicked(true);
  };

  return (
    <div className={` ${constrainWidth && styles.wrapper}`}>
      <div className={styles.videoBlockContainer}>
        {isplayClicked ? (
          <div className="videoPlay">
            <video width="100%" autoPlay controls>
              <source src={slice.primary.url} type="video/mp4"></source>
            </video>
          </div>
        ) : (
          <div className="videoBlock">
            <Image
              src={slice.primary.playButtonIcon.url}
              alt={slice.primary.playButtonIcon.alt}
              width={slice.primary?.playButtonIcon?.dimensions?.width}
              height={slice.primary?.playButtonIcon?.dimensions?.height}
              className="playIconBtn"
              onClick={() => playVideoHandler()}
            />
          </div>
        )}
      </div>

      <style jsx>
        {`
          .playIconBtn {
            cursor: pointer;
          }
          .videoBlock {
            background-image: url(${slice.primary.mobileBackgroundImage.url});
            background-repeat: no-repeat;
            background-size: cover;
            height: 336px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          @media (min-width: 768px) {
            .videoBlock {
              background-image: url(${slice.primary.desktopBackgroundImage.url});
              height: 560px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Video;
