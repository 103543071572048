import React, { useCallback, FC } from 'react';
import WhiteArrowUp from '@components/icons/WhiteArrowUp';
import ShareGreyIconFacebook from '@components/icons/ShareGreyIconFacebook';
import ShareGreyIconInstagram from '@components/icons/ShareGreyIconInstagram';
import ShareGreyIconTwitter from '@components/icons/ShareGreyIconTwitter';
import ShareGreyIconPinterest from '@components/icons/ShareGreyIconPinterest';

const openLink = (url) => {
  if (!process.browser) {
    return;
  }
  //@ts-ignore
  window.open(url, window, 'width=480,height=560');
};

interface Props {
  pageInfo: { url: string; seoTitle: string };
  featuredImage: {
    alt?: string;
    copyright?: string;
    dimensions: { width: number; height: number };
    url: string;
  };
}

const PageSocialShare: FC<Props> = ({ pageInfo, featuredImage }) => {
  const facebook = useCallback(() => {
    openLink(`http://www.facebook.com/sharer.php?u=${encodeURIComponent(pageInfo.url)}`);
  }, []);

  const twitter = useCallback(() => {
    openLink(
      `https://twitter.com/intent/tweet?hashtags=therejectshop&original_referer=${encodeURIComponent(
        pageInfo.url
      )}&text=${encodeURIComponent(`${pageInfo.seoTitle}\n\n${pageInfo.url}`)}`
    );
  }, []);

  const pinterest = useCallback(() => {
    const shareUrl = [
      `http://pinterest.com/pin/create/button`,
      `?url=${encodeURIComponent(pageInfo.url)}`,
      `&media=${encodeURIComponent(featuredImage.url)}`,
      `&description=${encodeURIComponent(pageInfo.seoTitle)}`,
    ];

    openLink(shareUrl.join(''));
  }, []);

  return (
    <div className="bg-white relative p-4">
      <div className="flex items-center justify-between">
        <div onClick={facebook}>
          <ShareGreyIconFacebook className="mr-5 cursor-pointer" />
        </div>
        <div onClick={pinterest}>
          <ShareGreyIconPinterest className="mr-5 cursor-pointer" />
        </div>
        <div onClick={twitter}>
          <ShareGreyIconTwitter className="cursor-pointer" />
        </div>
      </div>

      <div className="absolute -top-[6px] left-1/2 transform -translate-x-1/2 ">
        <WhiteArrowUp />
      </div>
    </div>
  );
};

export default PageSocialShare;
