import React, { useState, useEffect, useContext } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { PrismicRichText } from '@prismicio/react';
import Slider from 'react-slick';
import styles from './FeatureGrid.module.css';
import { useRouter } from 'next/router';
import { EventContext } from 'providers/EventProvider';

const FeatureGrid = ({ slice }) => {
  const [gridItems, setGridItems] = useState([]);
  const [isShowMoreClicked, setIsShowMoreClicked] = useState(false);
  const { heading, constrainWidth, headingAlignment, columns, tilesPerPage, mobileGridLayout } =
    slice.primary;
  const { gtm } = useContext(EventContext);
  const showMoreButton = slice.items.length > tilesPerPage;
  const tilesPerPageCounts = parseInt(tilesPerPage, 10);
  const router = useRouter();

  const settings = {
    dots: true,
    lazyLoad: 'ondemand',
    infinite: true,
    speed: 500,
    slidesToShow: tilesPerPageCounts,
    slidesToScroll: tilesPerPageCounts,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: tilesPerPageCounts,
          slidesToScroll: tilesPerPageCounts,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    if (tilesPerPage) {
      setGridItems(slice.items.slice(0, tilesPerPage));
    }
  }, [slice]);

  const handleClick = (item) => {
    const title = item.title[0].text;
    gtm.tagClickCategory({ label: `${title} - ${heading}` });
    if (item.link) router.push(item.link);
  };

  const showMoreHandler = () => {
    setGridItems(slice.items);
    setIsShowMoreClicked(true);
  };

  const showLessHandler = () => {
    setGridItems(slice.items.slice(0, tilesPerPage));
    setIsShowMoreClicked(false);
  };

  return (
    <div className={`${constrainWidth && styles.wrapper}`}>
      <div className={styles.grid__heading} style={{ textAlign: headingAlignment }}>
        {heading}
      </div>
      <div className={styles.featureGridRow}>
        <div className="grid-slick">
          {mobileGridLayout === 'carousel' ? (
            <Slider {...settings}>
              {gridItems.map((item, i) => {
                const ImageWidth = item?.image?.dimensions?.width;
                const ImageHeight = item?.image?.dimensions?.height;
                const key = Math.floor(Math.random() * 100);
                return (
                  <button type="button" key={i} onClick={() => handleClick(item)}>
                    <div className={styles.grid__item} key={i}>
                      <Image
                        src={item.image.url}
                        alt={item.image.alt || heading[0].text}
                        width={ImageWidth}
                        height={ImageHeight}
                      />
                      <div className={styles.grid__itemTitle}>
                        {item.title && <PrismicRichText field={item.title} />}
                      </div>
                    </div>
                  </button>
                );
              })}
            </Slider>
          ) : (
            <div
              className={`${styles.grid__body} ${
                (columns === '4' || columns == null) && styles.grid__4Cols
              } ${columns === '6' && styles.grid__6Cols}`}
            >
              {gridItems.map((item, i) => {
                const ImageWidth = item?.image?.dimensions?.width;
                const ImageHeight = item?.image?.dimensions?.height;
                return (
                  <button type="button" key={i} onClick={() => handleClick(item)}>
                    <div className={styles.grid__item}>
                      <Image
                        src={item.image.url}
                        alt={item.image.alt || heading[0].text}
                        width={ImageWidth}
                        height={ImageHeight}
                      />
                      <div className={styles.grid__itemTitle}>
                        {item.title && <PrismicRichText field={item.title} />}
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          )}
          {showMoreButton && !isShowMoreClicked && (
            <button type="button" className={styles.showMoreBtn} onClick={() => showMoreHandler()}>
              Show more
            </button>
          )}
          {showMoreButton && isShowMoreClicked && (
            <button type="button" className={styles.showMoreBtn} onClick={() => showLessHandler()}>
              Show less
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureGrid;
