import { PrismicRichText } from '@prismicio/react';
import breakPoints from 'utils/styleConfig';
import cn from 'clsx';
import SimpleLink from '../../components/SimpleLink';

const IntroductionTopBanner = ({ slice }) => {
  return (
    <section>
      <div
        key={slice.primary.title}
        className="intro-top-banner-container"
        style={{
          backgroundImage: `url(${slice.primary.image.url})`,
        }}
      >
        <div className="intro-top-banner wrapper-large">
          <div
            className={cn({ 'intro-container-right': slice.primary.text_alignment === 'Right' })}
          >
            <div className="intro-main-title">{slice.primary.title}</div>
            <div className="intro-main-description">
              <PrismicRichText field={slice.primary.description} />
            </div>
            <div className="intro-main-description-mobile">{slice.primary.description_mobile}</div>
            <SimpleLink linkUrl={slice.primary.link}>
              <button type="button" className="intro-main-btn">
                {slice.primary.button_text}
              </button>
            </SimpleLink>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .intro-top-banner-container {
            height: 500px;
            color: #ffffff;
            background-position: right 30% bottom 50%;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 30px;
          }
          .intro-top-banner {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            height: 100%;
          }
          .intro-container-right {
            grid-column-start: 2
          }
          .intro-main-title {
            font-size: 50px;
            font-weight: 600;
            padding-bottom: 16px;
            text-transform: uppercase;
            line-height: 1;
            color: #ffffff;
          }
          .intro-main-description {
            font-size: 16px;
            color: #ffffff;
            margin: 16px 0;
          }
          .intro-main-btn {
            background-color: #ffffff;
            color: #000000;
            margin-top: 20px;
            padding: 7px 50px;
            border-radius: 50px;
            cursor: pointer;
            font-size: 14px;
            text-transform: uppercase;
          }
          .intro-main-btn:hover {
            opacity: 0.8;
          }
          .intro-main-description-mobile {
            display: none;
          }
          @media (max-width: ${breakPoints.medium}) {
            .intro-top-banner-container {
              height: 400px;
              background-position: right 18% bottom 50%;
              text-align: center;
            }
            .intro-top-banner {
              grid-template-columns: repeat(1, 1fr);
            }
            .intro-main-title {
              font-size: 38px;
              padding: 0 180px;
            }
            .intro-main-description {
              display: none;
            }
            .intro-main-description-mobile {
              display: block;
              padding: 10px 40px;
            }
            .intro-main-btn {
              margin-top: 10px;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .intro-top-banner-container {
              height: 300px;
            }
            .intro-main-title {
              font-size: 28px;
              padding: 0 80px;
            }
            .intro-main-btn {
              padding: 7px 30px;
            }
          }
          @media (min-width: 1024px) {
            .intro-top-banner-container {
              margin-bottom: 80px;
            }
        `}
      </style>
    </section>
  );
};

export default IntroductionTopBanner;
