import Button from '@components/ui/TrsButton/Button';
import { PrismicRichText } from '@prismicio/react';
import clsx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import Slider from 'react-slick';
import styles from './ImageGrid.module.css';

const ImageGrid = ({ slice }) => {
  const { constrainWidth, heading, buttonText, buttonUrl } = slice.primary;
  const brandItems = slice.items;

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className={clsx(constrainWidth && styles.wrapper)}>
      <div className={styles.imageGrid}>
        <div className={styles.heading}>{heading}</div>
        <div className="imageGridCarousel">
          <Slider {...settings}>
            {brandItems.map((item, i) => (
              <Link href={item.linkUrl ? item.linkUrl : '#'} key={i}>
                <div className={styles.grid__item} key={i}>
                  <Image
                    src={item.image.url}
                    alt={item.image.alt || heading}
                    width={item.image.dimensions?.width}
                    height={item.image.dimensions?.height}
                  />
                  <div className={styles.grid__itemTitle}>
                    {item.title && <PrismicRichText field={item.title} />}
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
        {buttonText && (
          <div className="pt-12 md:pt-9 md:flex md:justify-center">
            <Link href={buttonUrl}>
              <Button variant="transparent" size="medium">
                {buttonText}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageGrid;
