import React, { useEffect, useState } from 'react';
import SimpleProductCard from '@components/SimpleProductCard/SimpleProductCard';
import { parseShopifyProductForProductCard } from 'utils/product';
import { getShopifyProduct } from 'containers/PDP/utils';
import ProductCard from 'components/ProductCard';

const ProductHandle = ({ handle, eventCategory, variation }) => {
  const [product, setProduct] = useState(null);

  useEffect(() => {
    getShopifyProduct(handle).then((res) => setProduct(res));
  }, [handle]);

  if (!product) return null;

  return (
    <div className="productCardContainer">
      {product && variation === 'simpleProductCard' && (
        <SimpleProductCard
          product={parseShopifyProductForProductCard(product)}
          eventCategory={eventCategory}
        />
      )}

      {product && variation === 'normalProductCard' && (
        <ProductCard
          product={parseShopifyProductForProductCard(product)}
          eventCategory={eventCategory}
        />
      )}
    </div>
  );
};

const ShopifyProductHandles = ({ slice }) => {
  const { items, variation } = slice;

  if (!items || !items.length) return null;

  return (
    <>
      <section className="grid gap-8 grid-cols-1 lg:grid-cols-3">
        {items.map((item, index) => (
          <ProductHandle handle={item.shopify_product_handle} key={index} variation={variation} />
        ))}
      </section>
    </>
  );
};

export default ShopifyProductHandles;
