import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { createClient } from '@lib/prismicio';
import dynamic from 'next/dynamic';
import { getCategoryIcon } from '@lib/helpers/get-parent-category-icon';
import FeaturedArticleCard from '@components/blog/FeaturedArticleCard/FeaturedArticleCard';
import ArticleCardV2 from '@components/blog/ArticleCardV2/ArticleCardV2';
import { ArrowRight } from '@components/icons';
import * as Sentry from '@sentry/nextjs';
import Support from '@components/Support';

const ArticlesRow = ({ slice }) => {
  const { heading, short_description, view_all_button_text, category_handle, is_featured_row } =
    slice.primary;
  const [articles, setArticles] = useState([]);
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [error, setError] = useState(null);

  const client = createClient();

  const IconComponent = dynamic(() =>
    import(`../../components/icons/${getCategoryIcon(category_handle)}`)
  );

  useEffect(() => {
    try {
      async function fetchCategoryArticles() {
        const parentCategoryArticles = await client.getAllByTag(`pc-${category_handle}`);
        setArticles(parentCategoryArticles.slice(0, 4));

        setFeaturedArticles(
          parentCategoryArticles
            .filter((article) => article.data.is_featured_on_blogs_listing === true)
            .slice(0, 4)
        );
      }

      fetchCategoryArticles();
    } catch (err) {
      setError(err);
      Sentry.captureException(err);
    }
  }, []);

  if (error) return <Support showErrorMessage={true} />;

  return (
    <div className="wrapper-large">
      <div className="flex items-center justify-between pt-7 md:pt-9">
        <div>
          <div className="flex items-center mb-5 md:mb-3">
            <IconComponent className="w-7 h-7 md:w-9 md:h-9 mr-2" fill="#CC0117" />
            <h2 className="font-semibold text-brand-dark-red text-2xl md:text-32">{heading}</h2>
          </div>

          <p className="font-normal text-dark-grey leading-6">{short_description}</p>
        </div>

        {/* view all category articles */}
        <Link href={`/blogs/${category_handle}`}>
          <div
            className="py-6 px-5  items-center hidden md:flex w-[304px] justify-center cursor-pointer"
            style={{ border: '1px solid', borderColor: 'black' }}
          >
            <IconComponent className="w-5 h-5 mr-4" fill="#000000" />

            <div className="flex items-center">
              {view_all_button_text}
              <ArrowRight className="ml-4" />
            </div>
          </div>
        </Link>
      </div>

      {/* articles result */}
      {!is_featured_row && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 mt-10">
          {articles &&
            articles.length !== 0 &&
            articles.map((article) => (
              <div key={article.uid}>
                <ArticleCardV2 article={article} />
              </div>
            ))}
        </div>
      )}

      {is_featured_row && featuredArticles.length !== 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mt-7">
          <div>
            <FeaturedArticleCard article={featuredArticles[0]} isFirst={true} />
          </div>
          <div>
            {featuredArticles.slice(1, 4).map((article) => (
              <div key={article.uid}>
                <FeaturedArticleCard article={article} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticlesRow;
