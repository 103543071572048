import { SharedSlice } from '@prismicio/types';
import DefaultBanner from './Default/Banner';
import VariantOneBanner from './VariantOne/Banner';

const CarouselBanner = ({ slice }: { slice: SharedSlice }) => {
  const renderVariant = () => {
    switch (slice.variation) {
      case 'variantOne':
        return <VariantOneBanner slice={slice} />;
      default:
        return <DefaultBanner slice={slice} />;
    }
  };

  return renderVariant();
};

export default CarouselBanner;
