import React from 'react';
import Image from 'next/image';
import SimpleLink from '@components/SimpleLink';
import breakPoints from 'utils/styleConfig';

const EventCategoryGrid = ({ slice }) => (
  <section>
    <div id={slice.primary.section_id} className="event-category-grid wrapper">
      <div className="event-category-grid-container">
        {slice.items.map((item, index) => (
          <div className="event-category-grid-card" key={index}>
            <SimpleLink linkUrl={item.link} key={`${item.link}-${item.button_text}`}>
              <div className="event-category-grid-card-top">
                <Image
                  src={item?.image.url}
                  alt={(item?.image && item?.image.alt) || 'The Reject Shop'}
                  width={item?.image?.dimensions?.width}
                  height={item?.image?.dimensions?.height}
                />
                <div className="event-category-grid-card-button">{item?.button_text}</div>
              </div>
            </SimpleLink>
          </div>
        ))}
      </div>
    </div>
    <style jsx>
      {`
        .wrapper {
          max-width: 100%;
          padding-left: 1rem;
          padding-right: 1rem;
          margin: auto;
        }
        .event-category-grid {
          margin: 30px auto;
        }
        @media (min-width: 1024px) {
          .wrapper {
            max-width: 1440px;
            padding-left: 4rem;
            padding-right: 4rem;
            margin: auto;
          }
          .event-category-grid {
            margin: 80px auto;
          }
        }
        .event-category-grid-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
          }
          .event-category-grid-card {
            max-width: 360px;
            margin-bottom: 10px;
            border: none;
          }
          .event-category-grid-card-top {
            position: relative;
            width: 100%;
          }
          .event-category-grid-card-top img {
            max-width: 100%;
          }
          .event-category-grid-card-button {
            position: absolute;
            width: 60%;
            top: 85%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            background-color: #fff;
            color: #000;
            font-size: 14px;
            line-height: 20px;
            padding: 10px 0;
            text-align: center;
            border: none;
            cursor: pointer;
            border-radius: 20px;
          }

          .event-category-grid-card-button:hover {
            color: #e2001a;
            text-decoration: underline;
          }

          @media (max-width: ${breakPoints.medium}) {
            .event-category-grid-container {
              justify-content: center;
            }
            .event-category-grid-card-button {
              width: 80%;
            }
          }
        }
      `}
    </style>
  </section>
);

export default EventCategoryGrid;
