import Image from 'next/legacy/image';
import Link from 'next/link';
import ArrowRightSmall from '@components/icons/ArrowRightSmall';
import { truncateText } from '@lib/helpers/text-truncate';

const ArticleCard = ({ handle, name, image, description }) => {
  const imageUrl = image?.url;
  const imageWidth = image?.dimensions?.width;
  const imageHeight = image?.dimensions?.height;

  return (
    <>
      <div className="flex flex-row md:flex-col w-full cursor-pointer">
        <div className="flex-1 articleImageContainer">
          <Link href={`/blog/${handle}`} prefetch={false}>
            <Image src={imageUrl} alt={name} height={396} width={304} />
            <div className="overlay"></div>
          </Link>
        </div>
        <div className="flex-1 flex flex-col ml-5 md:ml-0 ">
          <Link href={`/blog/${handle}`} prefetch={false}>
            <div className="font-normal text-text-dark uppercase text-label mt-0  md:mt-3">
              {name}
            </div>
            <p className="font-normal text-text-subdued text-body2 mt-2">
              {truncateText(description) || name}
            </p>
            <div className="flex font-normal text-text-subdued text-body2 mt-2">
              <span>Read more </span>
              <span className="pl-1.5 pt-1.5">
                <ArrowRightSmall />
              </span>
            </div>
          </Link>
        </div>
      </div>
      <style jsx>
        {`
          .articleImageContainer {
            position: relative;
          }

          .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: 0.5s ease;
            background: #464544;
          }

          .articleImageContainer:hover .overlay {
            opacity: 0.25;
          }
        `}
      </style>
    </>
  );
};

export default ArticleCard;
