/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
const ShareGreyIconTwitter = ({ fill, ...props }: { fill?: string; className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7715 9.55275C16.776 9.6585 16.779 9.76425 16.779 9.8715C16.779 13.1243 14.3025 16.8757 9.774 16.8757C8.3835 16.8757 7.08975 16.4685 6 15.7703C6.19275 15.7928 6.3885 15.804 6.58725 15.804C7.74075 15.804 8.802 15.411 9.645 14.7502C8.56725 14.7308 7.65825 14.019 7.34475 13.041C7.4955 13.0695 7.64925 13.0845 7.80825 13.0845C8.0325 13.0845 8.25 13.0545 8.457 12.9983C7.3305 12.7725 6.48225 11.7773 6.48225 10.5848V10.5533C6.81375 10.7378 7.194 10.8488 7.5975 10.8615C6.93675 10.4198 6.50175 9.666 6.50175 8.8125C6.50175 8.361 6.62325 7.938 6.8355 7.57425C8.04975 9.06375 9.864 10.044 11.91 10.1468C11.868 9.96675 11.8462 9.7785 11.8462 9.58575C11.8462 8.226 12.9487 7.1235 14.3077 7.1235C15.0165 7.1235 15.6562 7.42275 16.1047 7.90125C16.6658 7.791 17.193 7.58625 17.6685 7.30425C17.4848 7.87875 17.094 8.361 16.5863 8.66625C17.0842 8.60625 17.5583 8.47425 18 8.2785C17.67 8.772 17.2522 9.2055 16.7715 9.55275ZM12 0C5.37225 0 0 5.37225 0 12C0 18.627 5.37225 24 12 24C18.6278 24 24 18.627 24 12C24 5.37225 18.6278 0 12 0Z"
        fill="#85868A"
      />
    </svg>
  );
};

export default ShareGreyIconTwitter;
