export const getCategoryIcon = (categoryHandle) => {
  let iconType;

  switch (categoryHandle) {
    case 'holidays-and-parties':
      iconType = 'IconHolidaysAndEvents';
      break;
    case 'home-and-garden':
      iconType = 'IconHomeAndGarden';
      break;
    case 'craft-and-diy':
      iconType = 'IconDIY';
      break;
    case 'lifestyle':
      iconType = 'IconHealthAndBeauty';
      break;
    default:
      console.log(`Sorry, we are out of ${categoryHandle}.`);
  }

  return iconType;
};
