import PropTypes from 'prop-types';
import breakPoints from 'utils/styleConfig';

const PLPImageBanner = ({ image, mobile_image, title, overlay }) => {
  if (!image) return '';
  if (!mobile_image) {
    mobile_image = image;
  }
  return (
    <>
      <div className="plp-image-banner">
        <img src={image.url} className="plp-image-banner-desktop" />
        <img src={mobile_image.url} className="plp-image-banner-mobile" />
        {overlay && (
          <div className="overlay">
            <h1>{title}</h1>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .plp-image-banner {
            position: relative;
          }
          .plp-image-banner img {
              margin: 0;
              width: 100%;
              height: 100%;
          }
          .plp-image-banner-desktop {
            display: block;
          }
          .plp-image-banner-mobile {
            display: none;
          }
          .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.35);
            display: flex;
            align-items: center;
            padding: 0 60px;
          }
          .plp-image-banner h1 {
            font-size: 54px;
            color: #ffffff;
            text-transform: uppercase;
          }
          @media (max-width: ${breakPoints.small}) {
            .plp-image-banner-desktop {
              display: none;
            }
            .plp-image-banner-mobile {
              display: block;
            }
            .overlay {
              padding: 0 10px;
            }
            .plp-image-banner h1 {
              font-size: 35px;
              text-align: center;
            }
          }
        `}
      </style>
    </>
  );
};

PLPImageBanner.propTypes = {
  image: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
  mobile_image: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
  title: PropTypes.string,
  overlay: PropTypes.bool,
};

PLPImageBanner.defaultProps = {
  image: null,
  mobile_image: null,
  title: null,
  overlay: false,
};

export default PLPImageBanner;
