import React from 'react';
import Image from 'next/image';
import { ArrowRight } from '@components/icons';
import DarkRedClock from '@components/icons/DarkRedClock';
import Link from 'next/link';
import { getReadMins } from '@lib/helpers/get-blog-read-mins';
import { truncateText } from '@lib/helpers/text-truncate';
import { clsx } from 'clsx';

const FeaturedArticleCard = ({ article, isFirst }) => {
  const isFeaturedOnListing = article.data.is_featured_on_blogs_listing;
  return (
    <div>
      <div
        className={clsx('flex flex-row items-start mb-7', {
          'flex-row md:flex-col mb-0': isFirst,
        })}
      >
        <div className={clsx('mr-5', { 'w-full': isFirst, ' w-1/2': !isFirst })}>
          {article.data.featured_image && (
            <div>
              <Link href={`/blog/${article.uid}`} prefetch={false}>
                <div>
                  <div className="block lg:hidden">
                    <Image
                      src={`${
                        isFeaturedOnListing
                          ? article.data.featured_image.url + '&fit=crop&h=1280&w=960'
                          : article.data.featured_image.url
                      }`}
                      layout="responsive"
                      height={396}
                      width={304}
                      alt={article.data.featured_image.alt}
                      className="cursor-pointer "
                    />
                  </div>

                  <div className="hidden lg:block">
                    <Image
                      src={article.data.featured_image.url}
                      width={article.data.featured_image.dimensions.width}
                      height={article.data.featured_image.dimensions.height}
                      alt={article.data.featured_image.alt}
                      className="cursor-pointer "
                    />
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>

        <div className={clsx({ 'w-full md:w-full': isFirst, 'w-1/2 md:w-1/2': !isFirst })}>
          {article.data.article_heading && (
            <h2
              className={clsx('text-text-dark text-base font-450-weight mb-3', {
                'md:mt-3 md:leading-10 text-base md:text-32': isFirst,
              })}
            >
              {article.data.article_heading[0]?.text}
            </h2>
          )}

          {/* short description */}
          {article.data.article_short_description && (
            <p className="text-dark-grey text-13 leading-5 md:mb-5">
              {truncateText(article.data.article_short_description[0]?.text)}
            </p>
          )}

          <div className="flex  flex-col-reverse  md:flex-col">
            <div className="md:flex md:items-center ">
              <div className="flex items-center">
                <DarkRedClock />
                <span className="pl-2 text-brand-dark-red">{getReadMins(article)} mins read</span>
              </div>
            </div>

            {/* read more */}
            <Link href={`/blog/${article.uid}`} prefetch={false}>
              <div className="flex items-center my-3 cursor-pointer">
                <p className="pr-2 font-normal text-dark-grey text-13">Read More</p>
                <ArrowRight fill="#464544" className="w-3 h-3" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedArticleCard;
