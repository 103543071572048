import Script from 'next/script';

const CatalogueBlock = ({ slice }) => {
  const titleColour = slice.primary?.title_colour || '#E2001A';
  const viewerHeight = slice.primary?.container_height || '600';

  return (
    <section>
      <div className="online-catalogue wrapper-large">
        <h1 className="catalogue-heading" style={{ color: `${titleColour}` }}>
          {slice.primary.title}
        </h1>
        <div id="the-viewer" className="the-viewer" />
        <style jsx>
          {`
            .online-catalogue {
              margin: 30px auto;
            }
            .catalogue-heading {
              text-align: center;
            }
            .the-viewer {
              width: 100%;
              height: ${viewerHeight}px;
              margin: 30px 0;
            }
          `}
        </style>
        <Script
          dangerouslySetInnerHTML={{
            __html: `window.SFviewer({
              country: 'en_au', id: '${slice.primary?.api_id}',
              container_id: 'the-viewer'
            });`,
          }}
        />
      </div>
    </section>
  );
};

export default CatalogueBlock;
