import Image from 'next/image';

const IconInfoList = ({ slice }) => {
  const { items } = slice;

  return (
    <>
      <div className="wrapper my-0 blockContainer tablet:my-20">
        <div className="block tablet:mt-0 mt-1.5 tablet:flex">
          {items &&
            items.map((item) => {
              const ImageUrl = item?.image?.url;
              const ImageWidth = 30;
              const ImageHeight = 30;
              const title = item?.title;
              const text = item?.text;

              return (
                <div className="p-4 flex-1 tablet:py-6" key={item?.title}>
                  {ImageUrl && (
                    <div className="flex justify-center items-center mb-4 imageContainer">
                      <Image src={ImageUrl} alt={title} width={ImageWidth} height={ImageHeight} />
                    </div>
                  )}
                  {title && (
                    <div className="text-center text-13 leading-6 text-brand-dark-grey font-medium">
                      {title}
                    </div>
                  )}
                  {text && (
                    <div className="text-center pt-2 text-13 leading-6 text-brand-dark-grey font-normal">
                      {text}
                    </div>
                  )}
                </div>
              );
            })}
        </div>

        <style jsx>
          {`
            @media screen and (max-width: 768px) {
              .blockContainer {
                margin-left: 0px !important;
                margin-right: 0px !important;
                padding: 0px !important;
              }
            }
            .imageContainer {
              min-height: 32px !important;
            }
          `}
        </style>
      </div>
    </>
  );
};

export default IconInfoList;
