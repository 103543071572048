// Code generated by Slice Machine. DO NOT EDIT.

import Accordion from './Accordion';
import AccordionPanel from './AccordionPanel';
import ArticlesRow from './ArticlesRow';
import Banner from './Banner';
import BlogCategory from './BlogCategory';
import BlogGrid from './BlogGrid';
import BlogParentCategoriesControl from './BlogParentCategoriesControl';
import BrandList from './BrandList';
import CarouselBanner from './CarouselBanner';
import CatalogueBlock from './CatalogueBlock';
import CollectionGrid from './CollectionGrid';
import CollectionList from './CollectionList';
import EventCategoryGrid from './EventCategoryGrid';
import ExpandableTextArea from './ExpandableTextArea';
import FeatureGrid from './FeatureGrid';
import FreeText from './FreeText';
import HeadingAndDescription from './HeadingAndDescription';
import IconInfoList from './IconInfoList';
import ImageGrid from './ImageGrid';
import ImageOnly from './ImageOnly';
import ImageWithTitleAndDescription from './ImageWithTitleAndDescription';
import ImagesRow from './ImagesRow';
import InstagramComponent from './InstagramComponent';
import IntroductionTopBanner from './IntroductionTopBanner';
import MegaNavCallout from './MegaNavCallout';
import MegaNavColumn from './MegaNavColumn';
import NavBlock from './NavBlock';
import NavItem from './NavItem';
import Navigation from './Navigation';
import PromoBlock from './PromoBlock';
import RichContent from './RichContent';
import ShopifyProducts from './ShopifyProducts';
import SimpleBoxGrid from './SimpleBoxGrid';
import SocialGrid from './SocialGrid';
import Support from './Support';
import TableBlock from './TableBlock';
import TestimonialsBlock from './TestimonialsBlock';
import TextContent from './TextContent';
import ThreeImageTitleCards from './ThreeImageTitleCards';
import TitleAndLink from './TitleAndLink';
import TitleHeadingDescription from './TitleHeadingDescription';
import TitleWithButtonGrid from './TitleWithButtonGrid';
import TopReadBlogs from './TopReadBlogs';
import TwoByTwoBlock from './TwoByTwoBlock';
import TwoColumnsLifestyleGrid from './TwoColumnsLifestyleGrid';
import Video from './Video';

export {
  Accordion,
  AccordionPanel,
  ArticlesRow,
  Banner,
  BlogCategory,
  BlogGrid,
  BlogParentCategoriesControl,
  BrandList,
  CarouselBanner,
  CatalogueBlock,
  CollectionGrid,
  CollectionList,
  EventCategoryGrid,
  ExpandableTextArea,
  FeatureGrid,
  FreeText,
  HeadingAndDescription,
  IconInfoList,
  ImageGrid,
  ImageOnly,
  ImageWithTitleAndDescription,
  ImagesRow,
  InstagramComponent,
  IntroductionTopBanner,
  MegaNavCallout,
  MegaNavColumn,
  NavBlock,
  NavItem,
  Navigation,
  PromoBlock,
  RichContent,
  ShopifyProducts,
  SimpleBoxGrid,
  SocialGrid,
  Support,
  TableBlock,
  TestimonialsBlock,
  TextContent,
  ThreeImageTitleCards,
  TitleAndLink,
  TitleHeadingDescription,
  TitleWithButtonGrid,
  TopReadBlogs,
  TwoByTwoBlock,
  TwoColumnsLifestyleGrid,
  Video,
};

export const components = {
  accordion: Accordion,
  accordion_panel: AccordionPanel,
  articles_row: ArticlesRow,
  banner_block: Banner,
  blog_category: BlogCategory,
  blog_grid: BlogGrid,
  blog_parent_categories_control: BlogParentCategoriesControl,
  brand_list: BrandList,
  carousel_banner: CarouselBanner,
  catalogue_block: CatalogueBlock,
  collection_products_grid: CollectionGrid,
  collection_list: CollectionList,
  event_category_grid: EventCategoryGrid,
  read_more_block: ExpandableTextArea,
  homepage_collections_grid: FeatureGrid,
  free_text: FreeText,
  heading_and_description: HeadingAndDescription,
  service_block: IconInfoList,
  brands_block: ImageGrid,
  image_only: ImageOnly,
  image_with_title_and_description: ImageWithTitleAndDescription,
  blog_page_images_row: ImagesRow,
  code: InstagramComponent,
  introduction_top_banner: IntroductionTopBanner,
  mega_nav_callout: MegaNavCallout,
  mega_nav_column: MegaNavColumn,
  nav_block: NavBlock,
  nav_item: NavItem,
  navigation: Navigation,
  promo_block: PromoBlock,
  rich_content: RichContent,
  shopify_product_handles: ShopifyProducts,
  title_description_grid: SimpleBoxGrid,
  instagram_block: SocialGrid,
  support: Support,
  tabel_block: TableBlock,
  testimonials_block: TestimonialsBlock,
  blog_text_content: TextContent,
  three_image_title_cards: ThreeImageTitleCards,
  title_and_link: TitleAndLink,
  title_heading_description: TitleHeadingDescription,
  title_with_button_grid: TitleWithButtonGrid,
  top_read_blogs: TopReadBlogs,
  two_by_two_block: TwoByTwoBlock,
  two_columns_lifestyle_grid: TwoColumnsLifestyleGrid,
  video_block: Video,
};
