import Link from 'next/link';
import breakPoints from 'utils/styleConfig';

const BrandList = ({ slice }) => {
  const { items } = slice;
  if (!items || !items.length) return '';

  const group = items.reduce((result, item) => {
    if (item.brand_name) {
      const firstLetter = item.brand_name.substring(0, 1);
      result[firstLetter] = [...(result[firstLetter] || []), item];
    }
    return result;
  }, {});

  const brandFirstLetters = Object.keys(group).sort();
  const brandsArray = Object.entries(group).sort();

  return (
    <section>
      <div className="wrapper-large brand-list-block">
        <div className="brand-list-block-letters">
          {brandFirstLetters &&
            brandFirstLetters.map((letter, index) => (
              <div className="brand-list-letter" key={index}>
                <a href={`#brand-${letter}`}>
                  <span className="brand-list-letter-value" key={letter}>
                    {letter}
                  </span>
                </a>
              </div>
            ))}
        </div>
        <div className="brand-list-block-all-brands">
          {brandsArray &&
            brandsArray.map((brand, index) => {
              if (brand[1].length > 1) {
                brand[1].sort((a, b) => (a.brand_name > b.brand_name ? 1 : -1));
              }
              return (
                <div id={`brand-${brand[0]}`} className="brand-list-row-container" key={index}>
                  <span className="brand-list-row-letter">{brand[0]}</span>
                  <div className="brand-list-row-brand-names">
                    {brand[1].map((item) => (
                      <Link href={item.brand_link} key={item.brand_name}>
                        <span className="brand-list-row-brand-name">{item.brand_name}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <style jsx>
        {`
          .brand-list-block {
            margin: 30px auto;
          }
          .brand-list-block-letters {
            display: flex;
            flex-wrap: wrap;
          }
          .brand-list-letter {
            margin-bottom: 30px;
          }
          .brand-list-letter-value {
            text-transform: uppercase;
            background-color: #eee;
            font-size: 18px;
            border-radius: 34px;
            padding: 8px 22px;
            margin-right: 10px;
            font-weight: 400;
          }
          .brand-list-letter-value:hover {
            background-color: #282828;
            color: #feed00;
            text-decoration: underline;
          }
          .brand-list-block-all-brands {
            margin-top: 20px;
          }
          .brand-list-row-container {
            margin-bottom: 50px;
          }
          .brand-list-row-letter {
            text-transform: uppercase;
            font-size: 24px;
            line_height: 32px;
            font-weight: 700;
          }
          .brand-list-row-brand-names {
            display: flex;
            padding-top: 20px;
            flex-wrap: wrap;
          }
          .brand-list-row-brand-name {
            cursor: pointer;
            font-size: 16px;
            line_height: 24px;
            font-weight: 500;
            width: 170px;
            display: block;
          }
          .brand-list-row-brand-name:hover {
            color: #e2001a;
            text-decoration: underline;
          }
          @media (max-width: ${breakPoints.small}) {
            .brand-list-row-brand-name {
              width: 120px;
            }
          }
          @media (min-width: 1024px) {
            .brand-list-block {
              margin: 80px auto;
            }
          }
        `}
      </style>
    </section>
  );
};

export default BrandList;
