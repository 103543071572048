import Image from 'next/image';
import { FC } from 'react';
import styles from './SocialGrid.module.css';

interface Props {
  slice: any;
}

interface ItemProps {
  image: {
    url: string;
    alt: string;
    dimensions: any;
  };
  link: string;
}

interface ItemsProps {
  items: [ItemProps];
}

const SocialGrid: FC<Props> = ({ slice }) => {
  const { items }: ItemsProps = slice;

  return (
    <>
      <div className={styles.wrapper}>
        <div className="text-text-dark font-semibold text-mobileh4 md:text-h4 mb-8 uppercase">
          FOLLOW US @THEREJECTSHOP
        </div>
        <div className="grid gap-5 md:gap-x-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
          {items &&
            items.map((item: ItemProps, index: number) => {
              const imageUrl = item?.image?.url;
              const imageWidth = item?.image?.dimensions?.width;
              const imageHeight = item?.image?.dimensions?.height;
              const link = item.link || 'https://www.instagram.com/therejectshop';

              return (
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="block"
                >
                  <div className="cursor-pointer" key={index}>
                    <Image
                      src={imageUrl}
                      alt={item.image.alt || 'Image'}
                      width={imageWidth}
                      height={imageHeight}
                      layout="responsive"
                    />
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SocialGrid;
