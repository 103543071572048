import React from 'react';
import { RichText } from 'prismic-reactjs';

const RichContent = ({ slice }) => {
  return (
    <section>
      <div>{slice.primary.content && RichText.render(slice.primary.content)}</div>
      <style jsx>{`
        section {
        }
        .title {
        }
      `}</style>
    </section>
  );
};

export default RichContent;
