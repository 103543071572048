import clsx from 'classnames';
import SimpleLink from 'components/SimpleLink';
import Icon from 'components/Icon';

const ThreeImageTitleCards = ({ slice }) => {
  const bgColor = slice.primary?.background_colour || 'transparent';

  return (
    <section>
      <div style={{ backgroundColor: bgColor }}>
        <div className="wrapper-large">
          <div
            className={clsx(
              'three-image-title-cards',
              slice.primary?.background_colour && 'padding-vertical'
            )}
          >
            <div className="three-image-title-cards-title">{slice.primary?.title}</div>
            <div className="three-image-title-cards-container">
              {slice.items.map((item, index) => (
                <div className="three-image-title-cards-card" key={index}>
                  <SimpleLink linkUrl={item.link}>
                    <div className="three-image-title-cards-card-top">
                      <img
                        src={item.image.url}
                        alt={(item.image && item.image.alt) || 'The Reject Shop'}
                      />
                    </div>
                    <div className="three-image-title-cards-card-bottom">
                      {/* <div className="three-image-title-cards-card-bottom-container"> */}
                      <span className="three-image-title-cards-card-title">{item.title}</span>
                      <span className="three-image-title-cards-card-arrow">
                        <Icon icon="arrow-right-white" size="sm" />
                      </span>
                      {/* </div> */}
                    </div>
                  </SimpleLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .three-image-title-cards {
            max-width: 100%;
            margin: 30px auto;
          }
          .padding-vertical {
            padding: 30px 0;
          }
          .three-image-title-cards-title {
            text-transform: uppercase;
            font-size: 50px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
          }
          .three-image-title-cards-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .three-image-title-cards-card {
            flex: 1;
            max-width: 380px;
            margin-bottom: 10px;
          }
          .three-image-title-cards-card:not(:last-child) {
            padding-right: 5px;
          }
          .three-image-title-cards-card-top img {
            width: 100%;
          }
          .three-image-title-cards-card-bottom {
            background: #e2001a;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            height: 60px;
          }

          .three-image-title-cards-card-title {
            font-size: 22px;
            color: #ffffff;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 1;
            padding: 10px;
          }
          @media (max-width: 991px) {
            .three-image-title-cards-title {
              font-size: 30px;
            }
            .three-image-title-cards-container {
              flex-direction: column;
              align-items: center;
            }
            .three-image-title-cards-card {
              width: 100%;
              margin: 5px 0;
            }
            .three-image-title-cards-card:not(:last-child) {
              padding-right: 0;
            }
            .three-image-title-cards-card-title {
              font-size: 16px;
              padding: 0;
            }
          }
          @media (min-width: 1024px) {
            .three-image-title-cards {
              margin: 80px auto;
            }
          }
        `}
      </style>
    </section>
  );
};

export default ThreeImageTitleCards;
