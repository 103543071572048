import React, { useState } from 'react';
import Image from 'next/image';
import clsx from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import minusIcon from 'public/icons/minusIcon.svg';
import plusIcon from 'public/icons/plusIcon.svg';
import styles from './ExpandableTextArea.module.css';

const ExpandableTextArea = ({ slice }) => {
  const [showMoreText, setShowMoreText] = useState(false);
  const { constrainWidth, text, additionalText } = slice.primary;
  if (text.length === 1 && text[0].text.trim().length <= 0) {
    return null;
  }

  const readMoreBtnHandler = () => {
    setShowMoreText(!showMoreText);
  };

  const variation = slice.variation; // Use this to change the readmore slice to a different variant.
  const withTwoColumns = variation === 'twoColumns';
  const renderPrimaryText = () => {
    if (showMoreText && withTwoColumns) {
      return null;
    }

    return (
      <div
        className={clsx(
          styles.text,
          'text-center',
          {
            'lg:text-left lg:columns-2 lg:gap-10': withTwoColumns,
            'columns-1': !withTwoColumns,
          },
          !showMoreText && additionalText.length !== 0 && styles.addDots
        )}
      >
        <PrismicRichText field={slice.primary.text} />
      </div>
    );
  };
  const renderButtonText = () => {
    if (!showMoreText) {
      return 'Read more';
    }
    if (variation === 'default') {
      return 'Read less';
    }
    return 'Close';
  };

  return (
    <div className={clsx(constrainWidth && styles.wrapper)}>
      <div
        className={clsx(styles.expandableTextArea, 'mx-auto', {
          'mb-3 lg:mb-5 max-w-full': withTwoColumns,
          'my-8 lg:my-20 max-w-screen-lg': !withTwoColumns,
        })}
      >
        <div
          className={clsx(styles.heading, {
            'mb-10 text-h3': withTwoColumns,
            'mb-4 text-2xl': !withTwoColumns,
          })}
        >
          {slice.primary.heading}
        </div>
        {renderPrimaryText()}
        {additionalText.length > 0 && (
          <>
            <div
              className={clsx(styles.moreText, 'text-center', {
                'lg:text-left lg:columns-2 lg:gap-10': withTwoColumns,
                'columns-1': !withTwoColumns,
              })}
            >
              {showMoreText && withTwoColumns && <PrismicRichText field={slice.primary.text} />}
              {showMoreText && <PrismicRichText field={additionalText} />}
            </div>
            {additionalText.length > 1 ||
              (additionalText[0].text.trim().length > 0 && (
                <div className={clsx(styles.readMoreBtn, { 'pb-15 lg:pb-3': withTwoColumns })}>
                  <button
                    type="button"
                    className={clsx(styles.btn, withTwoColumns && styles.btnWithBorder, {
                      'my-4 lg:mt-12 lg:mb-12': withTwoColumns,
                    })}
                    onClick={() => readMoreBtnHandler()}
                  >
                    <span className={styles.btnText}>{renderButtonText()}</span>
                    {variation === 'default' && (
                      <Image src={showMoreText ? minusIcon : plusIcon} width={13} height={13} />
                    )}
                  </button>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ExpandableTextArea;
