import React from 'react';
import clsx from 'classnames';
import SimpleLink from '@components/SimpleLink';

const TitleWithButtonGrid = ({ slice }) => (
  <section>
    <div style={{ background: `${slice.primary?.background_colour}` }}>
      <div className="wrapper-large">
        <div
          className={clsx(
            'title-with-button-grid',
            slice.primary?.background_colour && 'padding-vertical'
          )}
        >
          <div className="title-with-button-grid-title">{slice.primary?.title}</div>
          <div className="title-with-button-grid-container">
            {slice.items.map((item, index) => {
              return (
                <div
                  className="title-with-button-grid-card"
                  key={index}
                  style={{ background: `${item.background_colour}` }}
                >
                  <div className="title-with-button-grid-card-title">{item.title}</div>

                  <SimpleLink linkUrl={item.link}>
                    <button type="button" className="title-with-button-grid-card-btn">
                      {item.button_text}
                    </button>
                  </SimpleLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    <style jsx>
      {`
        .title-with-button-grid {
          margin: 30px auto;
        }
        .padding-vertical {
          padding: 30px 0;
        }
        .title-with-button-grid-title {
          text-transform: uppercase;
          font-size: 50px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
        }
        .title-with-button-grid-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .title-with-button-grid-card {
          width: 32%;
          height: 250px;
          margin-bottom: 10px;
          padding: 0 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .title-with-button-grid-card-title {
          font-size: 26px;
          color: white;
          font-weight: 500;
          height: 100px;
          text-transform: uppercase;
          display: flex;
          align-items: flex-end;
        }
        .title-with-button-grid-card-btn {
          background-color: #ffffff;
          margin-top: 20px;
          width: 250px;
          padding: 7px 20px;
          border-radius: 50px;
          cursor: pointer;
          font-size: 14px;
          text-transform: uppercase;
        }
        .title-with-button-grid-card-btn:hover {
          opacity: 0.8;
        }
        @media (max-width: 991px) {
          .title-with-button-grid-title {
            font-size: 30px;
            margin-bottom: 10px;
          }
          .title-with-button-grid-container {
            flex-direction: column;
            align-items: center;
          }
          .title-with-button-grid-card {
            width: 100%;
            margin: 5px 0;
            align-items: center;
            height: 200px;
          }
          .title-with-button-grid-card-title {
            text-align: center;
            font-size: 20px;
            height: auto;
          }
        }
        @media (min-width: 1024px) {
          .title-with-button-grid {
            margin: 80px auto;
          }
        }
      `}
    </style>
  </section>
);

export default TitleWithButtonGrid;
