import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Icon from 'components/Icon';

const TestimonialsBlock = ({ slice }) => {
  const [isContentOpen, setIsContentOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const toggleContentExpand = (index) => {
    if (currentIndex !== index) {
      setIsContentOpen(true);
    } else {
      setIsContentOpen(!isContentOpen);
    }

    setCurrentIndex(index);
  };

  return (
    <section>
      <div className="wrapper-large">
        <div id="testimonials" className="testimonials-block">
          <div className="testimonials-block-title">{slice.primary.title}</div>
          <div className="testimonials-block-container">
            {slice.items.map((item, index) => {
              return (
                <div className="testimonials-block-card" key={index}>
                  <div className="testimonials-block-card-image">
                    <img src={item.image.url} alt={item.image.alt || 'The Reject Shop'} />
                  </div>
                  <div
                    className="testimonials-block-card-content"
                    style={{ height: isContentOpen && currentIndex === index ? 'auto' : '6rem' }}
                  >
                    <PrismicRichText field={item.content} />
                  </div>
                  <div className="testimonials-block-card-arrow">
                    <Icon
                      icon={
                        isContentOpen && currentIndex === index
                          ? 'careers-up-arrow'
                          : 'careers-down-arrow'
                      }
                      size="sm"
                      onClick={() => toggleContentExpand(index)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <style jsx global>
        {`
          .testimonials-block {
            max-width: 100%;
            margin: 30px auto;
          }
          .testimonials-block-title {
            text-transform: uppercase;
            font-size: 50px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
          }
          .testimonials-block-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            border: 1px solid #f0f0f0;
            box-shadow: 0 0 5px #f0f0f0;
          }
          .testimonials-block-card:nth-child(even) {
            flex-direction: row-reverse;
          }
          .testimonials-block-card-image {
            flex: 1;
          }
          .testimonials-block-card-image img {
            max-width: 100%;
          }
          .testimonials-block-card-content {
            flex: 1;
            font-size: 16px;
            padding: 0 20px;
            line-height: 1.5;
          }
          .testimonials-block-card-arrow {
            display: none;
          }
          .testimonials-block-card-content p {
            margin: 25px 0;
          }
          @media (min-width: 991px) {
            .testimonials-block-card-content {
              height: auto !important;
            }
          }
          @media (max-width: 991px) {
            .testimonials-block-title {
              font-size: 30px;
              margin-bottom: 10px;
            }
            .testimonials-block-card {
              margin: 10px 0;
              display: block;
            }
            .testimonials-block-card:nth-child(even) {
              flex-direction: column;
            }
            .testimonials-block-card-image {
              text-align: center;
            }
            .testimonials-block-card-content {
              font-size: 15px;
              padding: 10px;
              line-height: 1.3;
              overflow: hidden;
            }
            .testimonials-block-card-arrow {
              display: block;
              text-align: center;
              color: red;
              padding-top: 10px;
              cursor: pointer;
            }
            .testimonials-block-card-content p {
              margin: 10px 0;
            }
          }
          @media (min-width: 1024px) {
            .testimonials-block {
              margin: 80px auto;
            }
          }
        `}
      </style>
    </section>
  );
};

export default TestimonialsBlock;
