/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
const ShareGreyIconFacebook = ({ fill, ...props }: { fill?: string; className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1163 7.992L13.9867 7.99275C13.101 7.99275 12.9292 8.4135 12.9292 9.03075V10.3927H15.042L14.7668 12.5265H12.9292V18H10.7265V12.5265H8.8845V10.3927H10.7265V8.82C10.7265 6.99375 11.8418 6 13.47 6C14.25 6 14.9205 6.05775 15.1163 6.084V7.992ZM12 0C5.373 0 0 5.37225 0 12C0 18.627 5.373 24 12 24C18.6278 24 24 18.627 24 12C24 5.37225 18.6278 0 12 0Z"
        fill="#85868A"
      />
    </svg>
  );
};

export default ShareGreyIconFacebook;
