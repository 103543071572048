import { useUI } from '@components/ui/context';
import CompareAtPrice from 'components/Product/CompareAtPrice/index';
import Price from 'components/Product/Price';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { StorefrontContext } from 'providers/Storefront';
import { useContext, useEffect, useState } from 'react';
import { pluck } from 'utils';
import SSRLink from 'utils/SSRLink';
import { v4 as uuidv4 } from 'uuid';
import ProductAvailability from '../ProductAvailability';
import Badges from './Badges';
import Image from './Image';
import { clsx } from 'clsx';

const SimpleProductCard = ({
  product,
  insights,
  cardLocation,
  eventCategory,
  hasMinHeightTitle,
}) => {
  if (!product) return null;

  const { closeSearchPanel } = useUI();

  const { deliveryPostcode } = useContext(StorefrontContext);

  const { handle, title, compareAtPrice, price, named_tags: namedTags, tags } = product;
  const multibuyOffer = namedTags?.multibuy_offer;

  let availabilityTag = namedTags?.availability || namedTags?.Availability;
  // For ShopifyProductHandles component
  if (!availabilityTag) {
    availabilityTag = tags
      ?.find((tag) => tag.toLowerCase().includes('availability'))
      ?.toLowerCase()
      ?.replace('availability:', '');
  }

  const { deliveryStore, isStoreWithinRange } = useContext(StorefrontContext);
  const [availableOnline, setAvailableOnline] = useState(false);
  const [availableInStore, setAvailableInStore] = useState(false);
  const [storeLocationName, setStoreLocationName] = useState('');

  const availableMenuIds = product?.meta?.availablity?.doordash_menu_ids || [];
  const availableStoreIds = product?.meta?.availablity?.store_location_ids || [];
  const titleMinHeight = hasMinHeightTitle ? '48px' : '24px';

  useEffect(() => {
    const storeMenuId = deliveryStore?.storeTags
      ?.find((tag) => tag.includes('doordashmenuid'))
      ?.replace('doordashmenuid-', '');
    if (availableMenuIds.includes(storeMenuId) && isStoreWithinRange) {
      setAvailableOnline(true);
    } else {
      setAvailableOnline(false);
    }
  }, [isStoreWithinRange]);

  useEffect(() => {
    const storeLocationId = deliveryStore?.external_id;
    setStoreLocationName(deliveryStore?.name);

    if (availableStoreIds.includes(storeLocationId)) {
      setAvailableInStore(true);
    } else {
      setAvailableInStore(false);
    }
  }, [product]);

  const handleClick = () => {
    if (insights) {
      let uniqueUserId = Cookies.get('uniqueUserId');
      if (!uniqueUserId) {
        uniqueUserId = uuidv4();
        Cookies.set('uniqueUserId', uniqueUserId);
      }

      insights('clickedObjectIDsAfterSearch', {
        eventName: `Product Clicked${cardLocation ? ` ${cardLocation}` : ''}`,
        userToken: uniqueUserId,
      });
    }

    (window as any).dataLayer.push({
      event: 'gtm.customEvent',
      action: 'Product Card Click',
      category: eventCategory,
      label: title,
      value: deliveryPostcode,
      ecommerce: {
        items: [
          {
            item_id: product.sku,
            item_name: title,
            price: product.price,
            quantity: 1,
          },
        ],
      },
    });
    closeSearchPanel();
  };

  return (
    <div className={clsx('product-card simple-product-card px-4 py-3 ')}>
      <SSRLink linkUrl={`/p/${handle}`} linkType="Product" onClick={handleClick}>
        <div className="flex items-center">
          <div>
            <Image className="w-[112px]" src={pluck(product, 'images.0')} />
            <Badges product={product} multibuyOffer={multibuyOffer} />
          </div>
          <div>
            <div className="product-card-info flex">
              {availabilityTag && <ProductAvailability tag={availabilityTag} />}
              <div>
                {availableInStore && (
                  <div className="flex items-center text-sm mb-4 leading-4">
                    <img
                      className="w-10"
                      src="/icons/available-icon-green.svg"
                      alt="available-icon"
                    />
                    <span>{`Available at ${storeLocationName}`}</span>
                  </div>
                )}
              </div>

              <div>
                <div className="font-450-weight text-dark-grey text-13 leading-6">{title}</div>
                <div
                  className="product-card-price-container"
                  style={{ color: compareAtPrice > price ? '#df3c36' : '#474747' }}
                >
                  <Price
                    className=""
                    classNameMain="product-card-price-main product-card-price-main-small"
                    classNameSecondary="product-card-price-secondary product-card-price-secondary-small"
                    price={price}
                  />
                  {compareAtPrice > price && <CompareAtPrice compareAtPrice={compareAtPrice} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SSRLink>

      <style jsx>
        {`
          .product-card-title {
            min-height: ${titleMinHeight};
          }
        `}
      </style>
    </div>
  );
};

SimpleProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  cardLocation: PropTypes.string,
  eventCategory: PropTypes.string,
  hasMinHeightTitle: PropTypes.bool,
};

SimpleProductCard.defaultProps = {
  className: '',
  cardLocation: null,
  eventCategory: null,
  hasMinHeightTitle: false,
};

export default SimpleProductCard;
