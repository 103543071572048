import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import SimpleLink from '@components/SimpleLink';
import breakPoints from 'utils/styleConfig';

const TwoColumnsLifestyleGrid = ({ slice }) => (
  <section>
    <div className="lifestyle-grid-container wrapper" id={slice.primary.section_id}>
      <div className="lifestyle-grid">
        <div className="lifestyle-grid-text-container">
          <h2>{slice.primary.title}</h2>
          <div className="lifestyle-grid-description">
            <PrismicRichText field={slice.primary.text} />
          </div>
          <div className="lifestyle-grid-button-container">
            {slice.items.map((item) => (
              <SimpleLink
                linkUrl={item.button_link}
                key={`${item.button_link}-${item.button_text}`}
              >
                <button
                  type="button"
                  style={
                    item.outline_only
                      ? {
                          border: `1px solid ${item.button_colour}`,
                          color: item.text_colour,
                          backgroundColor: 'transparent',
                        }
                      : {
                          backgroundColor: item.button_colour,
                          color: item.text_colour,
                        }
                  }
                >
                  {item.button_text}
                </button>
              </SimpleLink>
            ))}
          </div>
        </div>
        <div className="lifestyle-grid-image-container">
          <img src={slice.primary.lifestyle_image.url} alt={slice.primary.lifestyle_image.alt} />
        </div>
      </div>
    </div>
    <style jsx>
      {`
        .wrapper {
          max-width: 100%;
          padding-left: 1rem;
          padding-right: 1rem;
          margin: auto;
        }
        .lifestyle-grid {
          display: flex;
          flex-direction: ${slice.primary.format === 'Text First' ? 'row' : 'row-reverse'};
          border: 1px solid #e7e7e7;
          border-radius: 2px;
          margin: 30px auto;
        }
        .lifestyle-grid-button-container {
          display: flex;
          flex-direction: column;
        }
        .lifestyle-grid-text-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 85px;
          width: 50%;
        }
        .lifestyle-grid-description {
          margin-bottom: 35px;
        }
        .lifestyle-grid-text-container h2 {
          font-weight: 400;
          font-size: 30px;
          margin-bottom: 20px;
        }
        .lifestyle-grid-image-container {
          width: 50%;
        }
        .lifestyle-grid-image-container img {
          width: 100%;
        }
        button {
          border-radius: 100px;
          transition: 0.3s;
          padding: 10px 25px;
          margin-bottom: 10px;
          font-size: 16px;
          cursor: pointer;
        }
        .lifestyle-grid-text-container a {
          text-decoration: underline;
        }
        @media (min-width: 1024px) {
          .wrapper {
            max-width: 1440px;
            padding-left: 4rem;
            padding-right: 4rem;
            margin: auto;
          }
          .lifestyle-grid {
            margin: 80px auto;
          }
        }
        @media (max-width: ${breakPoints.medium}) {
          .lifestyle-grid-text-container {
            padding: 0 40px;
          }
          .lifestyle-grid-text-container h2 {
            font-size: 22px;
          }
          .lifestyle-grid-button-container {
            flex-direction: row;
          }
          button {
            font-size: 14px;
            padding: 8px 14px;
            margin: 0 5px;
          }
        }
        @media (max-width: ${breakPoints.small}) {
          .lifestyle-grid {
            flex-direction: column-reverse;
          }
          .lifestyle-grid-text-container,
          .lifestyle-grid-image-container {
            width: 100%;
          }
          .lifestyle-grid-text-container {
            padding: 20px 40px;
            align-items: center;
            text-align: center;
          }
        }
      `}
    </style>
    <style global jsx>
      {`
        .lifestyle-grid-description a {
          text-decoration: underline;
        }
      `}
    </style>
  </section>
);

export default TwoColumnsLifestyleGrid;
