import pluck from './pluck';

const parseShopifyProductSupermarketTags = (tags) => {
  const supermarketTags = tags
    .split(',')
    .map((item) => item.trim())
    .filter((item) => item.startsWith('supermarket_'))
    .map((item) => item.replace('supermarket_', ''))
    .map((item) => {
      const [key, value] = item.split(':');
      return { key, value };
    });

  if (supermarketTags.length === 0) {
    return {};
  }

  const meta = {};
  supermarketTags.forEach((item) => {
    meta[item.key] = item.value;
  });

  return { supermarket: meta };
};

export const parseShopifyProduct = (product) => {
  if (!product) return null;
  const variant = pluck(product, 'variants.0');
  const { title, images, handle, tags } = product;
  const reMappedImages = images && images.length ? images.map((image) => pluck(image, 'src')) : [];
  const { price, compareAtPrice, sku } = variant;

  const supermarketMeta = parseShopifyProductSupermarketTags(product.tags);

  const parsedProduct = {
    ...product,
    images: reMappedImages,
    tags: tags || [],
    price,
    compareAtPrice,
    sku,
    meta: { ...product.meta, ...supermarketMeta },
  };

  return parsedProduct;
};

// This is for Prismic Shopify Product Handles slice
export const parseShopifyProductForProductCard = (product) => {
  if (!product) return null;
  const variant = pluck(product, 'variants.0');
  const { images, tags } = product;
  const reMappedImages = images && images.length ? images.map((image) => pluck(image, 'src')) : [];
  const { price, compareAtPrice, sku } = variant;

  const parsedProduct = {
    ...product,
    images: reMappedImages,
    tags: tags || [],
    price: price.amount,
    compareAtPrice: compareAtPrice?.amount,
    sku,
    meta: { ...product.metafields },
  };

  return parsedProduct;
};

export const parseAlgoliaProduct = (product) => {
  if (!product) return null;
  const { title, product_image, price, compare_at_price: compareAtPrice, handle } = product;

  return {
    ...product,
    images: product_image ? [product_image] : [],
    price,
    compareAtPrice,
  };
};

export const parseShopifyGraphProduct = (product) => {
  if (!product || !product.productByHandle) return null;

  const {
    id,
    handle,
    title,
    description,
    descriptionHtml,
    tags,
    images,
    variants,
    seo,
    metafields,
  } = product.productByHandle;

  return {
    id,
    title,
    handle,
    description,
    descriptionHtml,
    tags,
    seo,
    images: images.edges.map((a) => ({
      src: a.node.originalSrc,
    })),
    metafields: metafields.filter((meta) => meta !== null),
    variants: variants.edges.map((a) => a.node),
  };
};

export const parseRecentlyViewedProduct = (product) => {
  const variant = pluck(product, 'variants.0');
  if (!product || !variant) return null;

  const { images, tags } = product;
  const reMappedImages = images && images.length ? images.map((image) => pluck(image, 'src')) : [];
  const { price, compareAtPrice, sku } = variant;

  return {
    ...product,
    images: reMappedImages,
    tags: tags || [],
    price: price?.amount,
    compareAtPrice: compareAtPrice?.amount,
    sku,
    meta: { ...product.meta },
  };
};
