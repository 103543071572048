import React, { useState } from 'react';
import PageIntroductionBanner from '@components/PageIntroductionBanner';
import brandConfig from 'brandConfig';
import { ArrowLeft, ArrowRight } from '@components/icons';
import { createClient, getDocByUid } from '@lib/prismicio';
import { getReadMins } from '@lib/helpers/get-blog-read-mins';

const TopReadBlogs = ({ slice, previewData, locale }) => {
  const [index, setIndex] = useState(0);
  const [readMins, setReadMins] = useState(null);
  const { items } = slice;

  const client = createClient({ previewData });

  const getDocReadMins = async (handle) => {
    try {
      let document = await getDocByUid('blog_article', handle, client, locale);
      if (document) {
        setReadMins(getReadMins(document));
      }
    } catch (error) {
      if (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }
  };

  getDocReadMins(items[index].blogHandle);

  const domain = brandConfig.contexts[0].storefrontUrl;

  const nextIndexHandler = () => {
    if (index === items.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };
  const prevIndexHandler = () => {
    if (index === 0) {
      setIndex(items.length - 1);
    } else {
      setIndex(index - 1);
    }
  };

  return (
    <>
      <div className="mb-9 md:mb-14">
        <PageIntroductionBanner
          slice={items[index]}
          readMins={readMins}
          pageInfo={{
            url: domain + '/blog/' + items[index].blogHandle,
            seoTitle: items[index].heading[0].text,
          }}
        />
        <div className="wrapper-large  bg-[#F7DFDC] md:bg-transparent">
          <div className="bg-[#F7DFDC] max-w-[900px] mx-auto">
            <div className="px-4 pb-8 flex items-center md:-mt-16">
              <button onClick={prevIndexHandler}>
                <ArrowLeft fill="#464544" className="w-4 h-4 cursor-pointer" />
              </button>
              <span className="text-dark-grey text-13 px-5">
                {index + 1} / {items.length}
              </span>
              <button onClick={nextIndexHandler}>
                <ArrowRight fill="#464544" className="w-4 h-4 cursor-pointer" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopReadBlogs;
