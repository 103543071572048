import React from 'react';
import clsx from 'classnames';
import styles from './TitleHeadingDescription.module.css';

const TitleHeadingDescription = ({ slice }) => {
  const { constrainWidth, title, heading, description } = slice.primary;

  return (
    <div className={clsx(constrainWidth && styles.wrapper)}>
      <div className={styles.titleHeadingDescription}>
        <div className={styles.titleHeadingDescription_container}>
          <div className={styles.titleHeadingDescription_title}>{title}</div>
          <div className={styles.titleHeadingDescription_heading}>{heading}</div>
          <div className={styles.titleHeadingDescription_description}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default TitleHeadingDescription;
