import React from 'react';
import { PrismicRichText } from '@prismicio/react';

const BlogTextContent = ({ slice }) => {
  const { content } = slice.primary;
  return (
    <>
      <div className="text-ui-dark-grey [&>h2]:font-semibold [&>h2]:text-2xl [&>h2]:mb-8 [&>h3]:text-base  [&>h3]:font-medium [&>*]:mb-5 blogContentBody">
        {content && <PrismicRichText field={slice.primary.content} />}
      </div>

      <style jsx global>{`
        .blogContentBody a {
          font-weight: 400;
          text-decoration: underline;
          font-size: 18px;
        }
      `}</style>
    </>
  );
};

export default BlogTextContent;
