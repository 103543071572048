import React, { memo, FC } from 'react';
import Image from 'next/image';
import { Carousel } from 'react-responsive-carousel';
import { SharedSlice } from '@prismicio/types';
import Slide from './Slide';

interface Props {
  slice: SharedSlice;
}

const Banner: FC<Props> = ({ slice }) => {
  const { id, items, primary } = slice;
  const { variant } = primary;

  const variantOrderType = (variantType) => variantType?.toLowerCase().replace(/ /g, '_');

  const numberOfSlides = items.length > 1;

  // for multiple CarouselBanners
  const slideIndex = id.replace('$', '');

  return (
    <div className={`${variantOrderType(variant)}`}>
      <Carousel
        showStatus={false}
        showThumbs={false}
        useKeyboardArrows
        infiniteLoop
        autoPlay
        interval={6000}
        swipeScrollTolerance={30}
        preventMovementUntilSwipeScrollTolerance
        showIndicators={numberOfSlides}
        renderArrowPrev={(clickHandler, hasPrev, label) => {
          return (
            numberOfSlides && (
              <div className="carousel-arrow arrow-left" onClick={clickHandler}>
                <span className="icon-keyboard_arrow_left desktop-only">
                  <Image width={11} height={20} src="/icons/new-chevron-left.svg" alt="Prev" />
                </span>
                <span className="icon-keyboard_arrow_left mobile-only">
                  <Image
                    width={7}
                    height={12}
                    src="/icons/chevron-left-black-carousel.svg"
                    alt="Prev"
                  />
                </span>
              </div>
            )
          );
        }}
        renderArrowNext={(clickHandler, hasNext, label) => {
          return (
            numberOfSlides && (
              <div className="carousel-arrow arrow-right" onClick={clickHandler}>
                <span className="icon-keyboard_arrow_right desktop-only">
                  <Image width={11} height={20} src="/icons/new-chevron-right.svg" alt="Next" />
                </span>
                <span className="icon-keyboard_arrow_left mobile-only">
                  <Image
                    width={7}
                    height={12}
                    src="/icons/chevron-right-black-carousel.svg"
                    alt="Prev"
                  />
                </span>
              </div>
            )
          );
        }}
      >
        {items?.map((item, index) => (
          <Slide
            item={item}
            index={index}
            slideIndex={slideIndex}
            key={`carousel-slide-${index}`}
            variant={variant}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default memo(Banner);
