import Link from 'next/link';
import { EventContext } from 'providers/EventProvider';
import { useContext } from 'react';

const ImageOnly = ({ slice }) => {
  const { gtm } = useContext(EventContext);

  return (
    <section>
      <div className="image-only wrapper">
        {!!slice.primary.link && (
          <Link href={slice.primary.link}>
            <div onClick={() => gtm.tagClickImageOnly({ label: slice.primary?.link })}>
              <picture>
                <source media="(min-width:681px)" srcSet={slice.primary.image.url} />
                <img
                  className="image"
                  src={slice.primary.mobile_image?.url || slice.primary.image.url}
                  alt={slice.primary.title}
                />
              </picture>
            </div>
          </Link>
        )}
        {!slice.primary.link && (
          <picture>
            <source media="(min-width:681px)" srcSet={slice.primary.image.url} />
            <img
              className="image"
              src={slice.primary.mobile_image?.url || slice.primary.image.url}
              alt={slice.primary.title}
            />
          </picture>
        )}
      </div>
      <style jsx>
        {`
          .wrapper {
            max-width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            margin: auto;
          }
          .image-only {
            max-width: 100%;
            margin: 30px auto;
          }
          .image {
            width: 100%;
            height: auto;
          }
          @media (min-width: 1024px) {
            .wrapper {
              max-width: 1440px;
              padding-left: 4rem;
              padding-right: 4rem;
              margin: auto;
            }
            .image-only {
              margin: 80px auto;
            }
          }
        `}
      </style>
    </section>
  );
};

export default ImageOnly;
