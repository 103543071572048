import React from 'react';
import Link from 'next/link';
import clsx from 'classnames';
import Image from 'next/image';
import Button from '@components/ui/TrsButton/Button';
import rightArrowIcon from 'public/icons/rightArrowIcon.svg';
import styles from './Banner.module.css';

const Banner = ({ slice }) => {
  const {
    constrainWidth,
    contentAlignment,
    contentSpacing,
    ctaButtonStyle,
    ctaUrl,
    headingType,
    heading,
    ctaText,
    desktopImage,
    mobileImage,
    contentBlockBottomPosition,
    margin,
    headingHalfWidth,
  } = slice.primary;

  let contentBlockAlignment;
  let ctaBtnAlignment;
  let contentBlockJustifyContent;
  let bannerBlockMargin;

  switch (contentAlignment) {
    case 'center':
      contentBlockAlignment = 'contentBlockAlignmentCenter';
      ctaBtnAlignment = 'ctaBtnAlignmentCenter';
      break;
    case 'left':
      contentBlockAlignment = 'contentBlockAlignmentLeft';
      ctaBtnAlignment = 'ctaBtnAlignmentLeft';
      break;
    case 'right':
      contentBlockAlignment = 'contentBlockAlignmentRight';
      ctaBtnAlignment = 'ctaBtnAlignmentRight';
      break;
    default:
      contentBlockAlignment = 'contentBlockAlignmentCenter';
      ctaBtnAlignment = 'ctaBtnAlignmentCenter';
  }

  switch (contentSpacing) {
    case 'space out':
      contentBlockJustifyContent = 'space-between';
      break;
    case 'centered':
      contentBlockJustifyContent = 'start';
      break;
    default:
      contentBlockJustifyContent = 'space-between';
  }

  switch (margin) {
    case 'bottom':
      bannerBlockMargin = 'bannerBlockMarginBottomOnly';
      break;

    case 'top and bottom':
      bannerBlockMargin = 'bannerBlockMarginBottomAndTop';
      break;
    default:
      bannerBlockMargin = '';
  }

  const bannerContent = (
    <div className="bannerBlock">
      <div className={styles.emptyBlock}> </div>
      <div
        className={clsx(
          'contentBlock',
          contentBlockAlignment,
          contentBlockBottomPosition === true && 'mobileContentBlock'
        )}
      >
        <div
          className={clsx(
            headingHalfWidth &&
              contentAlignment === 'left' &&
              styles.headingHalfWidthContentAlignLeft,
            headingHalfWidth &&
              contentAlignment === 'right' &&
              styles.headingHalfWidthContentAlignRight
          )}
        >
          {headingType === 'h1' && <h1 className={styles.heading}>{heading}</h1>}
          {headingType === 'h2' && <h2 className={styles.heading}>{heading}</h2>}
        </div>

        <div className={`${ctaBtnAlignment}`}>
          {ctaButtonStyle === 'outline' && (
            <Link href={ctaUrl} passHref prefetch={false}>
              <Button variant="outlined" size="medium">
                {ctaText}
              </Button>
            </Link>
          )}
          {ctaButtonStyle === 'text with arrow' && (
            <button className={styles.simpleBtn}>
              <span className={styles.simpleBtnText}>{ctaText}</span>
              <Image src={rightArrowIcon} alt="right arrow" width={16} height={14} />
            </button>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .bannerBlock {
            background-image: url(${mobileImage?.url});
            background-repeat: no-repeat;
            background-size: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-position: center;
          }

          .contentBlock {
            height: 75%;
            width: 90%;
            max-width: 1440px;
            display: flex;
            flex-direction: column;
            justify-content: ${contentBlockJustifyContent};
          }

          .contentBlockAlignmentCenter {
            text-align: center;
          }

          .contentBlockAlignmentLeft {
            text-align: left;
          }

          .contentBlockAlignmentRight {
            text-align: right;
          }

          .ctaBtnAlignmentCenter {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 40px;
          }

          .ctaBtnAlignmentLeft {
            margin-right: auto;
            margin-left: 20px;
            margin-bottom: 25px;
          }

          .ctaBtnAlignmentRight {
            margin-left: auto;
            margin-right: 20px;
            margin-bottom: 25px;
          }

          .bannerBlockMarginBottomAndTop {
            margin: 40px 0px;
          }

          .bannerBlockMarginBottomOnly {
            margin-bottom: 10px;
          }

          @media (min-width: 767px) {
            .bannerBlock {
              background-image: url(${desktopImage?.url});
            }
          }

          @media (max-width: 768px) {
            .mobileContentBlock {
              justify-content: end;
            }
          }

          @media (min-width: 1024px) {
            .contentBlock {
              height: 60%;
            }

            .bannerBlockMarginBottomOnly {
              margin-bottom: 40px;
            }

            .bannerBlockMarginBottomAndTop {
              margin: 80px 0px;
            }
          }
        `}
      </style>
    </div>
  );

  return (
    <div className={clsx(constrainWidth === true && styles.wrapper)}>
      <div className={`${bannerBlockMargin} ${styles.bannerBlockContainer}`}>
        {ctaUrl ? (
          <Link href={ctaUrl} prefetch={false}>
            <a>{bannerContent}</a>
          </Link>
        ) : (
          bannerContent
        )}
      </div>
    </div>
  );
};

export default Banner;
