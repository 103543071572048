import React from 'react';
import PropTypes from 'prop-types';
import { parseAlgoliaProduct } from 'utils/product';

import ProductCard from '../ProductCard';
import PromoCard from '../PromoCard';

const Hit = ({ hit, insights, hasMinHeightTitle = false }) => {
  return hit.id ? (
    <ProductCard
      product={parseAlgoliaProduct(hit)}
      insights={insights}
      hasMinHeightTitle={hasMinHeightTitle}
    />
  ) : (
    <PromoCard promo={hit} order={hit.order} />
  );
};

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
  insights: PropTypes.func.isRequired,
  hasMinHeightTitle: PropTypes.bool,
};

Hit.defaultProps = {
  cardLocation: null,
  hasMinHeightTitle: false,
};

// what is aa?, confirm to delete safely
// const AlgoliaHit = connectHitInsights(aa)(Hit);

export default Hit;
