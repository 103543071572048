import ArticlesGrid from '../../components/blog/ArticlesGrid';
import styles from './BlogGrid.module.css';

const BlogBlock = ({ slice, context }) => {
  const { title, buttonTitle } = slice?.primary;
  const { articles } = context;

  if (!articles) return null;

  return (
    <div className={styles.wrapper}>
      <ArticlesGrid
        title={title || 'Blog'}
        buttonTitle={buttonTitle || 'Explore the blog'}
        articles={articles}
      />
    </div>
  );
};

export default BlogBlock;
