import React from 'react';

const TitleDescriptionGrid = ({ slice }) => (
  <section>
    <div className="wrapper-large">
      <div id="benefits" className="simple-box-grid">
        <div className="simple-box-grid-container">
          {slice.items.map((item, index) => (
            <div className="simple-box-grid-card" key={index}>
              <div className="simple-box-grid-card-title" style={{ color: `${item.title_colour}` }}>
                {item.title}
              </div>
              <div className="simple-box-grid-card-description">{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <style jsx>
      {`
        .simple-box-grid {
          max-width: 100%;
          margin: 30px auto;
        }
        .simple-box-grid-container {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }
        .simple-box-grid-card {
          background-color: #ffffff;
          text-align: center;
          width: 23%;
          margin-bottom: 10px;
          padding: 20px;
          border: 1px solid #474747;
          text-align: left;
        }
        .simple-box-grid-card:not(:last-child) {
          margin-right: 10px;
        }
        .simple-box-grid-card-title {
          font-size: 26px;
          font-weight: 600;
          text-transform: uppercase;
          height: 80px;
          line-height: 1.1;
          display: flex;
          align-items: center;
        }
        .simple-box-grid-card-description {
          font-size: 16px;
          padding: 20px 0;
        }
        @media (max-width: 991px) {
          .simple-box-grid-container {
            flex-direction: column;
          }
          .simple-box-grid-card {
            width: 100%;
            margin: 5px 0;
            text-align: center;
          }
          .simple-box-grid-card-title {
            font-size: 20px;
            height: 30px;
            justify-content: center;
          }
          .simple-box-grid-card-description {
            font-size: 14px;
            padding: 10px 0;
          }
        }
        @media (min-width: 1024px) {
          .simple-box-grid {
            margin: 80px auto;
          }
        }
      `}
    </style>
  </section>
);

export default TitleDescriptionGrid;
