import { FC } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';
import styles from './CollectionProducts.module.css';
import { useHits } from 'react-instantsearch';

interface Props {
  title: string;
  columns: number;
  hits: [];
  HitComponent: any;
}

const CollectionProducts: FC<Props> = (props: any) => {
  const { title, columns, HitComponent } = props;
  const { hits } = useHits(props);

  const sortedHits = [...hits];
  const theNumberOfColumns: number = Number(columns);

  const sliderSettings = {
    className: cn({ 'hide-slider': sortedHits.length < theNumberOfColumns }),
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: theNumberOfColumns,
    slidesToScroll: theNumberOfColumns,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: cn({ 'hide-slider': sortedHits.length < 4 }),
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          className: cn({ 'hide-slider': sortedHits.length < 3 }),
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          className: cn({ 'hide-slider': sortedHits.length < 2 }),
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div
      className={cn(`${styles.wrapper} product-slick`, {
        hidden: sortedHits.length === 0,
      })}
    >
      <div className="uppercase text-text-dark font-semibold text-20 leading-6 tablet:text-h4">
        {title}
      </div>
      <div className="mt-8">
        <Slider {...sliderSettings}>
          {sortedHits.map((hit: any, index: number) => {
            return (
              <HitComponent
                key={index}
                hit={hit}
                hasMinHeightTitle
                cardLocation="featured products"
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default CollectionProducts;
