import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import AccordionPanel from 'containers/AccordionPanel';

const InformationPageAccordion = ({ items }) => {
  return (
    <section id="accordion-items" className="flex flex-col flex-wrap w-full mt-1 mb-5">
      {items.map((item, index) => (
        <AccordionPanel
          key={index}
          label={item.item}
          content={RichText.render(item.item_description)}
          image={item.item_image}
        />
      ))}
    </section>
  );
};

InformationPageAccordion.propTypes = {
  items: PropTypes.array.isRequired,
};

export default InformationPageAccordion;
