import FeaturedProducts from '@components/Product/FeaturedProducts';
import SearchClient, { getDefaultSearchString } from '@lib/algolia';

const CollectionGrid = ({ slice }) => {
  const { title, columns, shopifyCollectionHandle } = slice.primary;

  const collectionHandle = shopifyCollectionHandle;
  const algoliaIndex = 'products';
  const defaultFilterString = getDefaultSearchString(
    `collections:${collectionHandle} AND NOT named_tags.availability:discontinued`
  );

  return (
    <FeaturedProducts
      title={title}
      columns={columns || 4}
      searchClient={SearchClient}
      indexName={algoliaIndex}
      defaultCategory={shopifyCollectionHandle}
      filterString={defaultFilterString}
    />
  );
};

export default CollectionGrid;
