import classnames from 'classnames';
import Image from 'next/image';
import Router from 'next/router';
import { RightArrowIcon } from '@components/icons';
import SimpleLink from '../../../components/SimpleLink';
import { slideStyles } from './slideStyles';
import { useContext } from 'react';
import { EventContext } from 'providers/EventProvider';

const LAYOUT_IMAGE_ONLY = 'Image Only';
const LAYOUT_CONTENT_LEFT = 'Content Left';
const LAYOUT_CONTENT_RIGHT = 'Content Right';
const LAYOUT_CONTENT_CENTER = 'Content Center';

const LinkableWrapper = ({
  applyLink = false,
  linkUrl,
  className,
  children,
  styles,
  isContainer = false,
  onClick,
}) => {
  if (applyLink) {
    if (isContainer) {
      <div
        className={className}
        styles={{ textDecoration: `none`, ...styles }}
        onClick={() => {
          Router.push(linkUrl);
        }}
      >
        {children}
      </div>;
    } else
      return (
        <SimpleLink
          linkUrl={linkUrl}
          className={className}
          styles={{ textDecoration: `none`, ...styles }}
        >
          {children}
        </SimpleLink>
      );
  }

  return children;
};

const OverlayableWrapper = ({ active, children }) => {
  if (active) {
    return (
      <div>
        {children}
        <style jsx>
          {`
            div {
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
            }
          `}
        </style>
      </div>
    );
  }

  return children;
};

const splitCharacter = '^';
const contentSplitPattern = new RegExp(/\^/i, 'ig');

const Slide = ({ item, index, variant, slideIndex }) => {
  const {
    background_image,
    background_colour,
    content_colour,
    content_accent_colour,
    image_tile,
    heading,
    subtext,
    layout,
    cta_1_image,
    cta_1_url,
    cta_1_title,
    cta_1_ga_event_label,
    cta_1_bg_colour,
    cta_1_text_colour,
    cta_2_image,
    cta_2_url,
    cta_2_title,
    cta_2_bg_colour,
    cta_2_text_colour,
    slide_link,
    mobile_content_order,
    cta_1_style,
    cta_2_style,
    constraint_width,
    heading_font_weight,
  } = item;
  const headingMode = heading?.indexOf(splitCharacter) === -1 ? 'automatic' : 'manual';
  const subtextMode = subtext?.indexOf(splitCharacter) === -1 ? 'automatic' : 'manual';
  const formattedHeading = heading?.replace(contentSplitPattern, '\n');
  const formattedSubText = subtext?.replace(contentSplitPattern, '\n');
  const layoutClass = layout?.toLowerCase()?.replace(' ', '-');
  const applyLinkToWholeTileCondition = cta_1_url && !cta_2_url;
  const contentBackgroundImageClass = background_image?.url ? 'backgroundImage-content' : '';
  const contentMode = background_image.url ? 'overlay' : 'normal';
  const { gtm } = useContext(EventContext);

  const tileClasses = classnames('carousel-tile', {
    'carousel-tile--left': layout === LAYOUT_CONTENT_LEFT,
    'carousel-tile--center': layout === LAYOUT_CONTENT_CENTER,
    'carousel-tile--right': layout === LAYOUT_CONTENT_RIGHT,
  });

  const ctaBtnClickHandler = (eventLabel, location) => {
    gtm.tagClickCarousel({ label: `${eventLabel}: ${location}` });
  };

  return (
    <LinkableWrapper
      isContainer
      key={index}
      applyLink={applyLinkToWholeTileCondition}
      linkUrl={cta_1_url}
      className="imageLink"
    >
      <div
        className={`slide-background slide-background--slide-${index} ${
          constraint_width === true ? 'wrapper-width' : ''
        }`}
      >
        {background_image.url && (
          <div className="slide-background-image">
            <Image
              width={background_image?.dimensions?.width}
              height={background_image?.dimensions?.height}
              src={background_image.url}
              alt={background_image?.alt ? background_image?.alt : 'background image'}
            />
          </div>
        )}
        <OverlayableWrapper active={contentMode === 'overlay'}>
          <div
            className={`carousel-slide slide-${index} slide-key-${slideIndex} ${layoutClass} ${
              mobile_content_order === 'Image First' ? '' : 'mobileOrder-content-first'
            }`}
            key={`carousel-${index}`}
          >
            <div
              className={`carousel-image ${layoutClass} ${
                mobile_content_order === 'Image First' ? '' : 'mobileOrder-content-first'
              }`}
              onClick={() => ctaBtnClickHandler(cta_1_ga_event_label, 'Image')}
            >
              {image_tile?.url && (
                <LinkableWrapper
                  applyLink={!!slide_link}
                  linkUrl={slide_link}
                  styles={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  {mobile_content_order === 'Image First' ? (
                    <Image
                      src={image_tile?.url}
                      alt={image_tile?.alt || 'The Reject Shop'}
                      slidelayout={layout}
                      width={image_tile.dimensions.width}
                      height={image_tile.dimensions.height}
                    />
                  ) : (
                    <img src={image_tile?.url} alt={image_tile?.alt || 'The Reject Shop'} />
                  )}
                </LinkableWrapper>
              )}
            </div>
            {layout !== LAYOUT_IMAGE_ONLY && (
              <div className={tileClasses}>
                <div className={`carousel-content ${contentBackgroundImageClass} ${layoutClass}`}>
                  {formattedHeading && (
                    <div
                      className={`carousel-heading ${heading_font_weight}`}
                      dangerouslySetInnerHTML={{
                        __html: formattedHeading,
                      }}
                    />
                  )}
                  {formattedSubText && <div className="carousel-subtext">{formattedSubText}</div>}
                  {cta_1_image?.url ? (
                    <div className="carousel-cta-image">
                      <SimpleLink linkUrl={cta_1_url}>
                        <img src={cta_1_image?.url} alt={cta_1_image?.alt || 'The Reject Shop'} />
                      </SimpleLink>
                      <SimpleLink linkUrl={cta_2_url}>
                        <img
                          src={cta_2_image?.url}
                          alt={cta_2_image?.alt || 'The Reject Shop'}
                          className="cta-image2"
                        />
                      </SimpleLink>
                    </div>
                  ) : (
                    <div className="carousel-button">
                      {cta_1_style === 'Outline' && (
                        <SimpleLink linkUrl={cta_1_url} className="carousel-cta-link">
                          <button
                            onClick={() => ctaBtnClickHandler(cta_1_ga_event_label, 'CTA Button')}
                            type="button"
                            className="carousel-cta cta-colour-1"
                          >
                            {cta_1_title}
                          </button>
                        </SimpleLink>
                      )}
                      {cta_1_style === 'Text with arrow' && (
                        <SimpleLink linkUrl={cta_1_url}>
                          <button
                            className="simpleBtn1"
                            onClick={() => ctaBtnClickHandler(cta_1_ga_event_label, 'CTA Button')}
                            type="button"
                          >
                            <span className="simpleBtnText">{cta_1_title}</span>
                            <RightArrowIcon fill={cta_1_text_colour} />
                          </button>
                        </SimpleLink>
                      )}
                      {cta_2_style === 'Outline' && (
                        <SimpleLink linkUrl={cta_2_url}>
                          <button
                            type="button"
                            className="carousel-cta cta-colour-2"
                            onClick={() => ctaBtnClickHandler(cta_1_ga_event_label)}
                          >
                            {cta_2_title}
                          </button>
                        </SimpleLink>
                      )}
                      {cta_2_style === 'Text with arrow' && (
                        <SimpleLink linkUrl={cta_2_url}>
                          <button
                            className="simpleBtn2"
                            onClick={() => ctaBtnClickHandler(cta_1_ga_event_label, 'CTA Button')}
                            type="button"
                          >
                            <span className="simpleBtnText">{cta_2_title}</span>
                            <RightArrowIcon fill={cta_2_text_colour} />
                          </button>
                        </SimpleLink>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </OverlayableWrapper>
        <style jsx global>
          {`
            .slide-key-${slideIndex}.slide-${index} .carousel-heading > em {
              ${content_accent_colour
                ? `color: ${content_accent_colour}`
                : `color: ${content_colour}`};
              font-style: normal;
            }
            .carousel-heading.Normal {
              font-weight: 400;
            }
            .carousel-heading.Bold {
              font-weight: 700;
            }
          `}
        </style>
        <style jsx>
          {`
            .slide-background--slide-${index} {
              ${background_image?.url ? `background-image: url('${background_image?.url}');` : ''};
              background-size: contain;
              ${background_colour ? `background-color: ${background_colour};` : ''};
            }
            .carousel-heading {
              ${headingMode === 'manual' ? `white-space: pre-wrap;` : ''};
              font-family: futura-pt, sans-serif;
              ${content_colour ? `color: ${content_colour};` : `color: black`};
            }
            .carousel-subtext {
              ${content_colour ? `color: ${content_colour};` : `color: black`};
              ${subtextMode === 'manual' ? `white-space: pre-wrap;` : ''};
            }
            .cta-colour-1 {
              ${cta_1_bg_colour
                ? `background-color: ${cta_1_bg_colour};`
                : `background-color: #ffffff`};
              ${cta_1_text_colour ? `color: ${cta_1_text_colour};` : `color: #000000`};
            }
            .cta-colour-2 {
              ${cta_2_bg_colour
                ? `background-color: ${cta_2_bg_colour};`
                : `background-color: #ffffff;`};
              ${cta_2_text_colour ? `color: ${cta_2_text_colour};` : `color: #000000`};
            }
            .simpleBtn1 {
              font-size: 16px;
              ${cta_1_text_colour ? `color: ${cta_1_text_colour};` : `color: #000000`};
              background-color: transparent;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
            .simpleBtn2 {
              font-size: 16px;
              ${cta_2_text_colour ? `color: ${cta_2_text_colour};` : `color: #000000`};
              background-color: transparent;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          `}
        </style>
        <style jsx>{slideStyles}</style>
      </div>
    </LinkableWrapper>
  );
};

export default Slide;
