import css from 'styled-jsx/css';
import { breakpointMedium, breakpointLarge, breakpointExtraLarge } from 'common/styles/breakpoints';

export const slideStyles = css`
  .wrapper-width {
    max-width: 1280px;
    margin: auto;
  }

  .simpleBtnText {
    padding-right: 6px;
  }

  .carousel-slide {
    max-width: 1280px;
    box-sizing: border-box;
    margin: 0 auto;
    height: 100%;
  }

  .slide-background {
    height: 100%;
  }

  @media (max-width: ${breakpointMedium}) {
    .carousel-slide {
      flex-direction: column;
      padding-bottom: 45px;
    }
    .carousel-slide.mobileOrder-content-first {
      padding-bottom: 0;
    }
  }

  .carousel-slide.image-only {
    max-width: initial;
  }

  .carousel-image {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel-image.mobileOrder-content-first img {
    max-height: 100%;
  }

  @media (max-width: ${breakpointMedium}) {
    .wrapper-width {
      max-width: 100%;
      margin: auto;
    }
    .carousel-image {
      padding: 50px 50px 0 50px;
    }
    .carousel-image.mobileOrder-content-first {
      padding: 0;
    }
  }

  .carousel-tile {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel-heading {
    font-size: 3.2em;
  }

  .carousel-slide.content-left .carousel-tile--left {
    justify-content: left;
  }

  .carousel-slide.content-left {
    flex-direction: row-reverse;
  }

  .carousel-slide.content-left .carousel-image {
    justify-content: flex-start;
    padding: 30px 60px 30px 0;
  }

  .carousel-slide.content-right .carousel-image {
    padding: 30px 0 60px 30px;
  }

  .carousel-slide.content-right .carousel-image.mobileOrder-content-first {
    padding: 0;
  }

  .carousel-content {
    width: 80%;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    padding: 30px 0;
  }

  @media (min-width: ${breakpointExtraLarge}) {
    .carousel-content {
      width: 90%;
    }
  }

  .carousel-cta {
    padding: 10px 30px 8px 30px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    font-family: futura-pt, sans-serif;
  }

  .carousel-content.backgroundImage-content {
    position: relative;
  }

  .carousel-background-image {
    display: initial;
    width: 100%;
  }

  .carousel-background-image a {
    display: block;
    height: 100%;
  }

  .carousel-tile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel-subtext {
    font-size: 1.5em;
    line-height: 30px;
    margin: 15px 0 30px 0;
    font-weight: 300;
  }

  .carousel-slide.content-center,
  .carousel-slide.content-right,
  .carousel-slide.content-left {
    display: flex;
    justify-content: center;
  }

  .carousel-slide.content-center {
    align-items: center;
  }

  .carousel-content.content-center {
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    text-align: center;
  }

  .carousel-content.content-left {
    padding-left: 80px;
    padding-right: 60px;
  }

  .carousel-content.content-right {
    padding-right: 80px;
    padding-left: 60px;
  }

  .carousel-cta-image {
    display: flex;
    padding-top: 10px;
  }

  .cta-image2 {
    padding-left: 10px;
  }

  .carousel-button {
    margin-top: 15px;
    display: flex;
  }

  .carousel-cta-link {
    padding-right: 10px;
  }

  @media (min-width: ${breakpointMedium}) and (max-width: ${breakpointLarge}) {
    .carousel-content {
      width: 100%;
    }

    .carousel-slide.content-left .carousel-content {
      margin-left: 0;
    }

    .carousel-heading {
      font-size: 2.5em;
      line-height: 1.3em;
    }

    .carousel-subtext {
      font-size: 1.4em;
    }
  }

  @media (max-width: ${breakpointMedium}) {
    .carousel-heading {
      font-size: 2.1em;
      line-height: 1.3em;
    }

    .carousel-subtext {
      font-size: 1.1em;
      line-height: 22px;
      margin: 15px 0;
    }

    .carousel-content {
      width: 100%;
      padding: 50px 0;
    }

    .carousel-slide.content-center.mobileOrder-content-first,
    .carousel-slide.content-right.mobileOrder-content-first,
    .carousel-slide.content-left.mobileOrder-content-first {
      flex-direction: column-reverse;
    }
  }
`;
