import AlgoliaHit from '@components/AlgoliaHit';
import { useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';
import CollectionProducts from './CollectionProducts';

interface props {
  title: string;
  columns: number;
  searchClient: any;
  indexName: string;
  filterString: string;
  defaultCategory: string;
}

const FeaturedProducts = ({
  title,
  columns,
  searchClient,
  indexName,
  filterString,
  defaultCategory,
}: props) => {
  const [hitsPerPage] = useState(columns * 2);

  if (!indexName) {
    return null;
  }

  return (
    <>
      <div className="featured_products">
        <InstantSearch searchClient={searchClient} indexName={indexName}>
          <Configure
            hitsPerPage={hitsPerPage}
            filters={filterString}
            ruleContexts={[defaultCategory]}
            distinct
            facetingAfterDistinct
            clickAnalytics
          />
          <div className="algolia_collection__product_list">
            <CollectionProducts
              hits={null}
              title={title}
              columns={columns}
              HitComponent={AlgoliaHit}
            />
          </div>
        </InstantSearch>
      </div>
      <style jsx>
        {`
          .featured_products {
            padding-top: 40px;
          }
        `}
      </style>
    </>
  );
};

export default FeaturedProducts;
