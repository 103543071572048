import React from 'react';
import Image from 'next/legacy/image';
import { ArrowRight } from '@components/icons';
import DarkRedClock from '@components/icons/DarkRedClock';
import Link from 'next/link';
import { getReadMins } from '@lib/helpers/get-blog-read-mins';
import { truncateText } from '@lib/helpers/text-truncate';

const ArticleCardV2 = ({ article }) => {
  const isFeaturedOnListing = article.data.is_featured_on_blogs_listing;

  return (
    <div>
      <div className="flex items-start mb-8 md:mb-2 md:flex-col ">
        <div className="mr-5 w-1/2 md:w-full">
          {article.data.featured_image.url && (
            <Link href={`/blog/${article.uid}`} prefetch={false}>
              <Image
                src={`${
                  isFeaturedOnListing
                    ? article.data.featured_image.url + '&fit=crop&h=1280&w=960'
                    : article.data.featured_image.url
                }`}
                layout="responsive"
                height={396}
                width={304}
                alt={article.data.featured_image.alt}
                className="cursor-pointer "
              />
            </Link>
          )}
        </div>

        <div className="w-1/2 md:w-full">
          {article.data.article_heading && (
            <h2 className="text-text-dark text-13 font-450-weight mb-3 md:mt-3">
              {article.data.article_heading[0]?.text}
            </h2>
          )}
          {/* short description */}
          {article.data.article_short_description && (
            <p className="text-dark-grey text-13 leading-5 ">
              {truncateText(article.data.article_short_description[0]?.text)}
            </p>
          )}

          <div className="md:flex md:items-center md:justify-between">
            {/* read more */}

            <Link href={`/blog/${article.uid}`} prefetch={false}>
              <div className="flex items-center my-3 cursor-pointer">
                <p className="pr-2 font-normal text-dark-grey text-13">Read More</p>
                <ArrowRight fill="#464544" className="w-3 h-3" />
              </div>
            </Link>

            <div className="flex items-center">
              <DarkRedClock />
              <span className="pl-2 text-brand-dark-red">{getReadMins(article)} mins read</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCardV2;
