import React, { useState } from 'react';
import Image from 'next/image';
import { PrismicRichText } from '@prismicio/react';
import DarkRedClock from '@components/icons/DarkRedClock';
import DarkRedShare from '@components/icons/DarkRedShare';
import styles from './PageIntroductionBanner.module.css';
import { clsx } from 'clsx';
import PageSocialShare from '@components/PageSocialShare/PageSocialShare';
import Link from 'next/link';

const PageIntroductionBanner = ({ slice, readMins, pageInfo, subCategories, featuredImage }) => {
  const { heading, shortDescription, subHeading, image, blogHandle } = slice.primary || slice;

  const [showShareIcons, setShowShareIcons] = useState(false);

  return (
    <div className={clsx('wrapper-large', styles.noPaddingXOnMobile)}>
      <div className="w-full bg-[#F7DFDC] px-5">
        <div className="flex flex-col md:flex-col items-center justify-between">
          <div className="flex flex-col md:flex-row items-center justify-between py-10 px-3 max-w-[900px] mx-auto w-full ">
            <div className="max-w-[400px] md:pr-8 pb-5 md:pb-8">
              {subHeading && <p className="text-brand-dark-red font-normal pb-4">{subHeading}</p>}

              {subCategories && subCategories.length !== 0 && (
                <p className="text-brand-dark-red font-normal pb-4">
                  {subCategories.map((item, index) => (
                    <span key={index} className="capitalize">
                      {item.sub_categories}
                      {index < subCategories.length - 1 && ', '}
                    </span>
                  ))}
                </p>
              )}

              {/* heading */}
              {heading && (
                <div className="[&>h1]:font-medium [&>h2]:font-medium [&>h1]:text-text-dark [&>h2]:text-text-dark [&>h1]:text-32 [&>h2]:text-32 mb-4">
                  <PrismicRichText field={heading} />
                </div>
              )}

              {/* short description */}
              {shortDescription && (
                <div className="font-normal text-dark-grey">
                  <PrismicRichText field={shortDescription} />
                </div>
              )}

              {/* mins to read & share row */}
              <div className="mt-4 flex items-center">
                {readMins && (
                  <div className="flex items-center">
                    <DarkRedClock />
                    <span className="pl-2 text-brand-dark-red">{readMins} mins read</span>
                  </div>
                )}

                <div className={clsx('relative z-10', { 'pl-7 ': readMins })}>
                  <div className="flex items-center">
                    <DarkRedShare />{' '}
                    <span
                      className="text-brand-dark-red pl-2 cursor-pointer"
                      onClick={() => setShowShareIcons(!showShareIcons)}
                    >
                      Share
                    </span>
                  </div>
                  {showShareIcons && (
                    <div className="absolute top-9 left-1/2 transform -translate-x-1/2">
                      <PageSocialShare pageInfo={pageInfo} featuredImage={featuredImage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* image */}
            <div>
              {featuredImage && (
                <div className="cursor-pointer">
                  <Link href={`/blog/${blogHandle}`}>
                    <Image
                      src={featuredImage.url}
                      width={featuredImage.dimensions.width}
                      height={featuredImage.dimensions.height}
                      alt={featuredImage.alt}
                    />
                  </Link>
                </div>
              )}

              {image && (
                <div className="cursor-pointer">
                  <Link href={`/blog/${blogHandle}`}>
                    <Image
                      src={image.url}
                      width={image.dimensions.width}
                      height={image.dimensions.height}
                      alt={image.alt}
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageIntroductionBanner;
