import React from 'react';
import cn from 'clsx';
import { PrismicRichText } from '@prismicio/react';

const TabelBlock = ({ slice }) => {
  const items = slice?.items;
  const headItems = Object.values(items[0]);
  const bodyItems = items.slice(1);
  return (
    <section className="py-4">
      <div className="text-h4 font-semibold font-sans text-brand-darkest-grey">
        {slice.primary.title && <PrismicRichText field={slice.primary.title} />}
      </div>
      <table
        className={cn(
          'w-full table-auto tablet:table-fixed border-collapse border border-ui-grey-25 mt-6'
        )}
      >
        <thead className="bg-brand-light-grey">
          <tr>
            {headItems?.map((title, index) => (
              <th
                key={index}
                className={`border text-body1 font-medium text-text-subdued border-solid border-ui-grey-25 py-2.5 pl-4 text-left bg-ui-lightest-grey body-2-bold tablet:w1/${
                  Object.values(items[0]).length
                }`}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bodyItems?.map((item, index) => {
            const columns = Object.entries(item).map(([key]) => item[key]);
            return (
              <tr key={index}>
                {columns.map((column, index) => (
                  <td
                    key={index}
                    className="text-body1 font-normal text-text-subdued border border-solid border-ui-grey-25 py-2.5 pl-4"
                  >
                    {column}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <style jsx>{``}</style>
    </section>
  );
};

export default TabelBlock;
