/* global document */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import breakPoints from 'utils/styleConfig';
import { EventContext } from 'providers/EventProvider';

const InstaGrid = ({ className }) => {
  const conatinerRef = useRef(null);
  const { gtm } = useContext(EventContext);

  useEffect(() => {
    const element = document.createElement('div');
    element.classList.add('elfsight-app-cd26b73d-b84a-4d6e-9459-6cef8940281d');
    conatinerRef.current.appendChild(element);

    const script = document.createElement('script');
    script.src = '//apps.elfsight.com/p/platform.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      conatinerRef.current?.removeChild(element);
    };
  }, []);
  return (
    <>
      <div className={`slice-wrapper wrapper ${className}`}>
        <div className="insta-content ">
          <h2>INSTAGRAM FEED</h2>

          <div id="elfsight-container" ref={conatinerRef} />

          <div className="insta-actions">
            <a
              href="https://www.instagram.com/therejectshop/"
              target="_blank"
              rel="noreferrer"
              onClick={() => gtm.tagClickFollowOnInstagram()}
            >
              Follow us on Instagram @therejectshop
            </a>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          #elfsight-container {
            width: 100%;
          }
          .slice-wrapper {
            display: flex;
            background-repeat: no-repeat;
            justify-content: center;
            margin-top: 0px;
            margin-bottom: 0px;
            min-height: 500px;
          }
          .insta-content {
            max-width: 1140px;
            margin: 50px 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          }
          h2 {
            margin-bottom: 30px;
            font-size: 30px;
            font-weight: 600;
            color: #e2001a;
          }
          .slice-wrapper h1 img {
            width: 34px;
            height: 34px;
            position: relative;
            top: 3px;
            margin-right: 22px;
          }
          .insta-actions {
            margin-top: 22px;
          }
          .insta-actions a {
            text-decoration: underline;
          }
          @media (max-width: ${breakPoints.medium}) {
            .slice-wrapper {
              background-image: none;
              min-height: auto;
              margin-bottom: 40px;
            }
            .insta-content {
              margin-top: 40px;
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          .eapps-instagram-feed-posts-view {
            flex-wrap: wrap;
            justify-content: space-evenly !important;
          }
          .eapps-instagram-feed-posts-item-template-tile {
            width: 23% !important;
          }
          @media (max-width: ${breakPoints.small}) {
            .eapps-instagram-feed-posts-item-template-tile {
              width: 47% !important;
              margin-bottom: 5px !important;
            }
          }
        `}
      </style>
    </>
  );
};

InstaGrid.propTypes = {
  className: PropTypes.string,
};

InstaGrid.defaultProps = {
  className: null,
};

export default InstaGrid;
