import React from 'react';
import Image from 'next/image';
import { clsx } from 'clsx';

const BlogPageImagesRow = ({ slice }) => {
  const columns = slice.items.length;

  if (slice.items.length === 0) return null;
  return (
    <>
      <div
        className={clsx(
          'flex flex-col my-8 gap-8 items-center [&>*:not(:first-child)]:hidden md:[&>*:not(:first-child)]:block',
          {
            'md:grid md:grid-cols-1': columns === 1,
            'md:grid md:grid-cols-2': columns === 2,
            'md:grid md:grid-cols-3': columns === 3,
            'md:grid md:grid-cols-4': columns === 4,
          }
        )}
      >
        {slice.items.map((item, index) => (
          <div key={index}>
            {item.image.url && (
              <Image
                src={item.image.url}
                width={item.image.dimensions.width}
                height={item.image.dimensions.height}
                alt={item.image.alt}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default BlogPageImagesRow;
