import React from 'react';
import clsx from 'classnames';

const TwoByTwoBlock = ({ slice }) => {
  return (
    <section>
      <div style={{ background: `${slice.primary?.background_colour}` }}>
        <div className="wrapper-large">
          <div
            className={clsx(
              'two-by-two-block',
              slice.primary?.background_colour && 'padding-vertical'
            )}
          >
            <div className="two-by-two-block-title">{slice.primary.title}</div>
            <div className="two-by-two-block-container">
              {slice.items.map((item, index) => {
                const hglColour = item.title_highlight_colour || '#ffffff';
                const hglStart =
                  item.title_highlight_start > -1 ? item.title_highlight_start : 1000;
                const hglEnd = item.title_highlight_end || 1000;

                return (
                  <div
                    className="two-by-two-block-card"
                    key={index}
                    style={{ background: `${item.background_colour}` }}
                  >
                    <div className="two-by-two-block-card-title">
                      {item.title.substring(0, hglStart)}
                      <span style={{ color: `${hglColour}` }}>
                        {item.title.substring(hglStart, hglEnd)}
                      </span>
                      {item.title.substring(hglEnd)}
                    </div>
                    <div className="two-by-two-block-card-description">{item.description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .two-by-two-block {
            margin: 30px auto;
          }
          .padding-vertical {
            padding: 30px 0;
          }
          .two-by-two-block-title {
            text-transform: uppercase;
            font-size: 50px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
          }
          .two-by-two-block-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
          }
          .two-by-two-block-card {
            text-align: center;
            width: 49%;
            margin-bottom: 10px;
            padding: 30px;
          }
          .two-by-two-block-card-title {
            font-size: 26px;
            color: #ffffff;
            font-weight: 600;
            text-transform: uppercase;
          }
          .two-by-two-block-card-description {
            font-size: 18px;
            padding: 10px 0;
            color: #ffffff;
          }
          @media (max-width: 991px) {
            .two-by-two-block-title {
              font-size: 30px;
              margin-bottom: 10px;
            }
            .two-by-two-block-container {
              flex-direction: column;
              align-items: center;
            }
            .two-by-two-block-card {
              width: 100%;
              margin: 5px 0;
              padding: 20px;
            }
            .two-by-two-block-card-title {
              font-size: 20px;
            }
            .two-by-two-block-card-description {
              font-size: 15px;
              padding: 10px 30px;
            }
          }
          @media (min-width: 1024px) {
            .two-by-two-block {
              margin: 80px auto;
            }
          }
        `}
      </style>
    </section>
  );
};

export default TwoByTwoBlock;
