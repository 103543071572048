/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
const ArrowLeft = ({ fill, ...props }: { fill?: string; className?: string }) => {
  return (
    <svg
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.5 0L10.2631 6H0.73686L5.5 0Z" fill="white" />
    </svg>
  );
};

export default ArrowLeft;
