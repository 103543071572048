import React from 'react';
import { PrismicRichText } from '@prismicio/react';

const FreeText = ({ slice }) => {
  return (
    <section>
      <div className="freeText wrapper-large">
        <PrismicRichText field={slice.primary.text} />
      </div>
      <style jsx global>
        {`
          .freeText {
            text-align: center;
            margin: 30px auto;
          }
          .freeText p {
            margin: 16px auto;
            max-width: 80%;
          }
          .freeText ul {
            margin: 16px;
          }
          .freeText li {
            list-style-type: disc;
            list-style-position: inside;
          }
          .freeText h1,
          .freeText h2 {
            font-size: 45px;
            font-weight: 600;
            margin-bottom: 30px;
            color: #e2001a;
          }
          @media (max-width: 680px) {
            .freeText h1,
            .freeText h2 {
              font-size: 28px;
            }
            .freeText li {
              list-style-position: outside;
              text-align: left;
              margin-left: 16px;
            }
          }
          @media (min-width: 1024px) {
            .freeText {
              margin: 80px auto;
            }
          }
        `}
      </style>
    </section>
  );
};

export default FreeText;
