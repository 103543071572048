import fullClient from 'algoliasearch';
import algoliasearch from 'algoliasearch/lite';
import config from 'brandConfig';

const appId = config.brand.algoliaApplicationID;
const apiKey = config.brand.algoliaApiToken;

// create algolia search client
export const SearchClient = algoliasearch(appId, apiKey);
export const algoliaClient = () => fullClient(appId, apiKey);

export default SearchClient;

export const getDefaultSearchString = (searchString?: string) => {
  if (!searchString) return `NOT named_tags.search:hidden`;

  return `${searchString} AND NOT named_tags.search:hidden`;
};

export const getFilterAttributeTitle = (attribute: string) => {
  const title = attribute;
  // if (attribute === 'named_tags.category') return 'Product Categories';
  // if (attribute?.toLowerCase() === 'named_tags.maincategory') return 'Category';
  if (attribute === 'named_tags.offer') return 'On Offer';
  return title?.replace('named_tags.', '').replace('_', ' ')?.toLowerCase();
};
