import React from 'react';
import PromoCard from 'components/PromoCard';
import breakPoints from 'utils/styleConfig';

const PromoBlock = ({ slice }) => (
  <section>
    <div id={slice.primary?.section_id} className="promo-card-block wrapper">
      {slice.items.map((item) => (
        <div className="promo-card-block-item" key={`${item.button_title}-${item.order}`}>
          <PromoCard promo={item} order={item.order} />
        </div>
      ))}
    </div>
    <style jsx>
      {`
        .wrapper {
          max-width: 100%;
          padding-left: 1rem;
          padding-right: 1rem;
          margin: auto;
        }
        .promo-card-block {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          justify-content: end;
          margin: 30px auto;
          gap: 16px;
        }
        .promo-card-block-item {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
        }
        @media (max-width: ${breakPoints.small}) {
          .promo-card-block {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        @media (min-width: 1024px) {
          .wrapper {
            max-width: 1440px;
            padding-left: 4rem;
            padding-right: 4rem;
            margin: auto;
          }
          .promo-card-block {
            margin: 80px auto;
          }
        }
      `}
    </style>
  </section>
);

export default PromoBlock;
