import React from 'react';
import PLPImageBanner from '../../components/Slices/PLPImageBanner';

const ImageWithTitleAndDescription = ({ slice }) => {
  const {
    title,
    description,
    description_mobile: descriptionMobile,
    image,
    mobile_image: mobileImage,
    title_overlay: titleOverlay,
  } = slice?.primary;

  const hasBannerImage = image && image.url !== undefined;

  return (
    <section>
      <div className="wrapper-large brands-page">
        {hasBannerImage && (
          <PLPImageBanner
            image={image}
            mobile_image={mobileImage}
            title={title}
            overlay={titleOverlay}
          />
        )}
        {!hasBannerImage && <h1 className="brands-page-title">{title}</h1>}
        {description && <div className="brands-page-description desktop-only">{description}</div>}
        {description && (
          <div className="brands-page-description mobile-only">{descriptionMobile}</div>
        )}
      </div>
      <style jsx>
        {`
          .brands-page {
            margin: 30px auto;
          }
          .brands-page-title {
            text-transform: uppercase;
            color: #e2001a;
            font-size: 40px;
            font-weight: 800;
            line-height: 48px;
          }
          .brands-page-description {
            font-size: 16px;
            background-color: #f5f5f5;
            padding: 15px;
            margin-top: 0px;
          }
          @media (min-width: 1024px) {
            .brands-page {
              margin: 80px auto;
            }
          }
        `}
      </style>
    </section>
  );
};

export default ImageWithTitleAndDescription;
