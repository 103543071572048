import React from 'react';
import { PrismicRichText } from '@prismicio/react';

const HeadingAndDescription = ({ slice }) => {
  if (!slice) return null;
  const { heading, description } = slice.primary;

  return (
    <div className="px-5 max-w-[870px] mx-auto mb-7 md:mb-16">
      {heading && (
        <div className="[&>h1]:text-2xl font-semibold leading-8 text-center text-text-dark mb-6">
          <PrismicRichText field={heading} />
        </div>
      )}
      {description && (
        <div className="text-450-weight text-dark-grey leading-6 text-center">
          <PrismicRichText field={description} />
        </div>
      )}
    </div>
  );
};

export default HeadingAndDescription;
