import React, { FC, useContext } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { AnyRegularField } from '@prismicio/types';
import styles from './Slide.module.css';
import { useRouter } from 'next/router';
import { EventContext } from 'providers/EventProvider';

interface Props {
  item: any;
  defaultLayout: AnyRegularField;
}

const Slide: FC<Props> = ({ item, defaultLayout }) => {
  const {
    layout,
    content_background_colour,
    image_background_colour,
    image,
    heading,
    heading_colour,
    heading_font_weight,
    subheading,
    subheading_colour,
    cta_text,
    cta_url,
    cta_text_colour,
    cta_background_colour,
    cta_ga_event_label,
  } = item;

  const slideLayout = layout === 'Default' ? defaultLayout : layout;
  const contentPosition = slideLayout === 'Image Left' ? 'orderLeft' : 'orderRight';
  const router = useRouter();
  const { gtm } = useContext(EventContext);

  const handleClick = () => {
    gtm.tagClickCarousel({ label: cta_ga_event_label });
    router.push(cta_url);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles[contentPosition]}
        style={{ backgroundColor: content_background_colour }}
      >
        <div className={styles.content}>
          <h2 className={styles.heading} style={{ color: heading_colour }}>
            {heading}
          </h2>
          <p className={styles.subheading} style={{ color: subheading_colour }}>
            {subheading}
          </p>
          <button onClick={handleClick}>
            <a
              className={styles.cta}
              style={{ color: cta_text_colour, backgroundColor: cta_background_colour }}
            >
              {cta_text}
            </a>
          </button>
        </div>
      </div>
      <div className={styles.imageSlide} style={{ backgroundColor: image_background_colour }}>
        <Image
          width={1800}
          height={1200}
          alt="background image"
          src={image.url}
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default Slide;
