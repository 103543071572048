import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import InformationPageAccordion from 'containers/InformationPageAccordion';

const AccordionPanel = ({ slice }) => {
  return (
    <section>
      <div className="my-4">
        {slice.primary.title ? (
          <PrismicRichText field={slice.primary.title} />
        ) : (
          <h3>Template slice, update me!</h3>
        )}
      </div>
      <InformationPageAccordion items={slice?.items} />
      <style jsx>{`
        section {
        }
      `}</style>
    </section>
  );
};

export default AccordionPanel;
