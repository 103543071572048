export const getReadMins = (article) => {
  let totalContentString = '';

  const contentSlicesArr = article?.data?.slices?.filter(
    (item) => item.slice_type === 'blog_text_content'
  );

  contentSlicesArr.map((contentSliceItem) =>
    contentSliceItem.primary.content.map((item) => (totalContentString += item.text))
  );

  let readMins = Math.round(totalContentString.length / 225);
  if (readMins > 10) {
    readMins = 10;
  }

  return readMins;
};
