import React, { FC } from 'react';
import Image from 'next/image';
import { Carousel } from 'react-responsive-carousel';
import { SharedSlice } from '@prismicio/types';
import Slide from './Slide';

interface Props {
  slice: SharedSlice;
}

const VariantOneBanner: FC<Props> = ({ slice }) => {
  const { id, items, primary } = slice;
  const numberOfSlides = items.length;

  const arrowNavigation = (clickHandler, direction) => (
    <div className={`carousel-arrow arrow-${direction}`} onClick={clickHandler}>
      <span className={`icon-keyboard_arrow_${direction} desktop-only`}>
        <Image width={11} height={20} src={`/icons/new-chevron-${direction}.svg`} alt="Prev" />
      </span>
      <span className={`icon-keyboard_arrow_${direction} mobile-only`}>
        <Image
          width={7}
          height={12}
          src={`/icons/chevron-${direction}-black-carousel.svg`}
          alt="Prev"
        />
      </span>
    </div>
  );

  // for multiple CarouselBanners
  const slideIndex = id.replace('$', '');

  return (
    <Carousel
      showStatus={false}
      showThumbs={false}
      useKeyboardArrows
      infiniteLoop
      autoPlay={false}
      interval={6000}
      swipeScrollTolerance={30}
      preventMovementUntilSwipeScrollTolerance
      showIndicators={numberOfSlides > 1}
      renderArrowPrev={(clickHandler, hasPrev, label) => {
        return numberOfSlides > 1 && arrowNavigation(clickHandler, 'left');
      }}
      renderArrowNext={(clickHandler, hasNext, label) => {
        return numberOfSlides > 1 && arrowNavigation(clickHandler, 'right');
      }}
    >
      {items?.map((item) => (
        <Slide key={id} item={item} defaultLayout={primary.default_layout} />
      ))}
    </Carousel>
  );
};

export default VariantOneBanner;
