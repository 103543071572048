/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
const ShareGreyIconPinterest = ({ fill, ...props }: { fill?: string; className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0009 0C5.373 0 0 5.3726 0 11.999C0 16.9137 2.9544 21.1349 7.1835 22.9908C7.1478 22.1522 7.1757 21.1475 7.3905 20.2361C7.6224 19.2613 8.9361 13.6961 8.9361 13.6961C8.9361 13.6961 8.5533 12.9299 8.5533 11.7982C8.5533 10.0195 9.5838 8.69163 10.8663 8.69163C11.9589 8.69163 12.4851 9.51186 12.4851 10.4929C12.4851 11.5894 11.7864 13.2317 11.4267 14.7513C11.1264 16.0245 12.0648 17.0631 13.3194 17.0631C15.5934 17.0631 17.1252 14.1419 17.1252 10.6828C17.1252 8.0532 15.3537 6.08453 12.1317 6.08453C8.4909 6.08453 6.2247 8.79873 6.2247 11.8306C6.2247 12.8768 6.5313 13.6133 7.0155 14.1839C7.2366 14.447 7.2678 14.5529 7.1868 14.8533C7.1292 15.0744 6.9984 15.606 6.9423 15.816C6.8628 16.1205 6.6171 16.2285 6.3414 16.1172C4.665 15.4314 3.8853 13.5971 3.8853 11.5327C3.8853 8.1234 6.7602 4.03665 12.4602 4.03665C17.0424 4.03665 20.0592 7.35118 20.0592 10.9102C20.0592 15.6177 17.4411 19.1353 13.5825 19.1353C12.2874 19.1353 11.0688 18.4339 10.6515 17.6386C10.6515 17.6386 9.9543 20.4044 9.8079 20.9375C9.5526 21.8621 9.0546 22.788 8.5998 23.5086C9.6786 23.8269 10.8195 24.0003 12.0009 24.0003C18.6285 24.0003 24 18.6277 24 11.9992C24 5.3729 18.6285 0.000300011 12.0009 0.000300011V0Z"
        fill="#85868A"
      />
    </svg>
  );
};

export default ShareGreyIconPinterest;
