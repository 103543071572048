const Navigation = ({ slice }) => (
  <section>
    <div className="navigation wrapper">
      <ul className="navigation-list">
        {slice.items.map((item) => (
          <li className="navigation-item" key={`${item.navigate_to}-${item.title}`}>
            {item.navigate_to_this_page && <a href={`#${item.navigate_to}`}>{item.title}</a>}
            {!item.navigate_to_this_page && <a href={item.navigate_to}>{item.title}</a>}
          </li>
        ))}
      </ul>
      <style jsx>
        {`
          .wrapper {
            max-width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            margin: auto;
          }
          .navigation {
            max-width: 100%;
            margin: 30px auto;
          }
          .navigation-list {
            border-top: 1px solid rgb(231, 231, 231);
            border-bottom: 1px solid rgb(231, 231, 231);
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          }
          .navigation-item {
            padding: 10px;
            font-size: 14px;
            color: rgb(17, 17, 17);
          }
          .navigation-item a:hover {
            text-decoration: underline;
          }
          @media (min-width: 1024px) {
            .wrapper {
              max-width: 1440px;
              padding-left: 4rem;
              padding-right: 4rem;
              margin: auto;
            }
            .navigation {
              margin: 80px auto;
            }
            .navigation-item {
              padding: 10px;
              font-size: 14px;
            }
            .navigation-item {
              padding: 10px 40px;
            }
          }
        `}
      </style>
    </div>
  </section>
);

export default Navigation;
