import shopify from 'services/ShopifyGQL';
import { parseShopifyGraphProduct } from 'utils/product';

const metafieldsIdentifier = `[
  {namespace: "availability", key: "click_and_collect_stores"},
  {namespace: "availability", key: "doordash_menu_ids"},
  {namespace: "availability", key: "store_location_ids"},
  {namespace: "global", key: "description_tag"},
  {namespace: "product", key: "summary"},
]`;

const productDataQuery = `
    id,
    tags,
    title,
    handle,
    description,
    descriptionHtml,
    seo {
      description
      title
    },
    images(first: 6) {
      edges {
        node {
          originalSrc
        }
      }
    },
    variants(first: 5) {
      edges {
        node {
          id,
          price {
            amount
          },
          compareAtPrice {
            amount
          },
          sku
        }
      }
    },
    metafields(identifiers: ${metafieldsIdentifier}) {
      namespace,
      key,
      value
    }`;

export const getRelatedProducts = async (handle, product, rootCollection) => {
  const meta = {};
  let related = [];

  if (product) {
    const productTags = product.tags;
    const relatedTags = productTags.filter((tag) => {
      return tag.includes('related:');
    });

    if (relatedTags.length > 0) {
      let query = '';

      relatedTags.forEach((tag, index) => {
        if (index > 0) query += ' OR ';
        query += `tag:'${tag}'`;
      });

      const products = await shopify(`{
        products(first:5, query:"${query}") {
          edges {
            node {
              ${productDataQuery}
            }
          }
        }
      }`);
      related = products.products.edges.map((a) => {
        return { productByHandle: a.node };
      });
      related = related?.filter((a) => a.productByHandle.handle !== handle).slice(0, 10);
    }
  }

  if (!related?.length && rootCollection) {
    const query = `{
      collectionByHandle(handle: "${rootCollection}") {
        products (first: 15) {
          edges {
            node {
              ${productDataQuery}
            }
          }
        }
      }
    }`;

    const results = await shopify(query);

    related =
      results.collectionByHandle?.products.edges
        .map((a) => {
          return { productByHandle: a.node };
        })
        ?.filter((a) => a.productByHandle.handle !== handle)
        .slice(0, 10) ?? [];
  }

  return { meta, related };
};

export const getShopifyProduct = async (handle) => {
  const product = await shopify(`{
    productByHandle(handle: "${handle}") {
      ${productDataQuery}
    }
  }`);

  if (!product) {
    return null;
  }

  return parseShopifyGraphProduct(product);
};
