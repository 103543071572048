import Link from 'next/link';
import { TrsButton } from '@components/ui';
import ArticleCard from '../ArticleCard';

const ArticlesGrid = ({ title, buttonTitle, articles }) => {
  return (
    <div className="my-10 laptop:my-20">
      <div className="uppercase text-text-dark font-semibold text-20 leading-6 tablet:text-h4">
        {title}
      </div>
      <div className="flex flex-col mt-6 md:mt-7 tablet:flex-row justify-between gap-6 laptop:gap-8">
        {articles.map((article) => {
          return (
            <ArticleCard
              key={article?.id}
              handle={article?.uid}
              name={article?.data?.article_heading[0].text}
              image={article?.data?.featured_image}
              description={article?.data?.article_short_description[0].text}
            />
          );
        })}
      </div>
      <div className="pt-12 md:pt-9 md:flex md:justify-center">
        <Link href="/blogs">
          <TrsButton variant="transparent" size="medium">
            {buttonTitle}
          </TrsButton>
        </Link>
      </div>
    </div>
  );
};

export default ArticlesGrid;
