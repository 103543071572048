import React, { useEffect, useState, useContext } from 'react';
import clsx from 'classnames';
import { StorefrontContext } from '../../providers/Storefront';
import Button from '../../components/Button';
import styles from './Support.module.css';

const Support = ({ slice }) => {
  const { constrainWidth, heading, description } = slice.primary;
  const { deliveryStore } = useContext(StorefrontContext);
  const [hasDeliveryStore, setHasDeliveryStore] = useState(false);

  useEffect(() => {
    if (deliveryStore?.storeName) setHasDeliveryStore(true);
  }, [deliveryStore]);

  const handleClick = () => {
    if (typeof window !== 'undefined') document.querySelector('.delivery-location span').click();
  };

  const handleZendeskOpen = () => {
    zE('messenger', 'open');
  };

  return (
    <div className={clsx(constrainWidth && styles.wrapper)}>
      <div className="support">
        <div className="support-heading">{heading}</div>
        <div className="support-description">{description}</div>
        <div className="support-buttons">
          <a href="tel:1800 861 021">
            <Button cta appearance="tertiary" iconBefore="phone" label="Show number" />
          </a>
          <a href="/stores">
            <Button cta appearance="tertiary" iconBefore="my-store" label="Store finder" />
          </a>
          {!hasDeliveryStore && (
            <Button
              onClick={handleClick}
              cta
              appearance="tertiary"
              iconBefore="cross-location"
              label="Set my local store"
            />
          )}
          <a href="mailto:service@rejectshop.com.au">
            <Button cta appearance="tertiary" iconBefore="mail-new" label="Email" />
          </a>
          <a href="/customerservice/faq">
            <Button cta appearance="tertiary" iconBefore="faqs" label="FAQ's" />
          </a>
          <Button
            onClick={handleZendeskOpen}
            cta
            appearance="tertiary"
            iconBefore="help-chat"
            label="Help"
          />
        </div>
      </div>

      <style jsx global>
        {`
          .support .buttonx {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        `}
      </style>
      <style jsx>
        {`
          .support {
            padding: 20px 0 40px;
          }
          .support-heading {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 700;
            color: #272727;
            padding-bottom: 6px;
          }
          .support-description {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
            color: #272727;
            padding-bottom: 20px;
          }
          .support-buttons {
            display: flex;
            overflow-x: auto;
          }
          .support-buttons::-webkit-scrollbar {
            display: none;
          }
          @media (max-width: 768px) {
            .support {
              padding: 30px auto;
            }
            .support-heading {
              font-size: 20px;
              line-height: 26px;
              font-weight: 600;
            }
            .support-description {
              font-size: 13px;
              line-height: 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Support;
