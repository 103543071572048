import React, { useState } from 'react';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import breakPoints from '@utils/styleConfig';
import { clsx } from 'clsx';
import Image from 'next/image';

const AccordionPanel = ({ label, content, image }) => {
  const [isActive, setIsActive] = useState(false);
  const [icon, setIcon] = useState('plus');
  const [displayContent, setDisplayContent] = useState('0px');

  const toggleAccordion = () => {
    setIsActive(isActive ? false : true);
    setDisplayContent(isActive ? 'none' : 'block');
    setIcon(isActive ? 'plus' : 'minus');
  };

  return (
    <div className="accordion-section">
      <button
        type="button"
        className={clsx('accordion-btn', 'flex flex-row justify-between items-center pt-4', {
          'pb-4': !isActive,
        })}
        onClick={toggleAccordion}
      >
        <div className={`text-text-subdued flex-wrap block text-body1 font-normal`}>{label}</div>
        <div className={clsx('accordion-icon', 'mr-2 lg:mr-5')}>
          <Icon icon={`${icon}`} size="sm" />
        </div>
      </button>
      {image.url && !isActive && (
        <Image
          src={image.url}
          width={image.dimensions.width}
          height={image.dimensions.height}
          alt={image.alt || label}
        />
      )}

      <div style={{ display: `${displayContent}` }} className="accordion-panel-content">
        {image.url && isActive && (
          <div className="mt-4">
            <Image
              src={image.url}
              width={image.dimensions.width}
              height={image.dimensions.height}
              alt={image.alt || label}
            />
          </div>
        )}
        {content}
      </div>
    </div>
  );
};

AccordionPanel.propTypes = {
  label: PropTypes.string,
  content: PropTypes.object,
};

export default AccordionPanel;
