import PropTypes from 'prop-types';

import SSRLink from 'utils/SSRLink';
import React, { useContext } from 'react';
import { EventContext } from 'providers/EventProvider';

const PromoCard = ({ promo, order }) => {
  const { gtm } = useContext(EventContext);

  const handleClick = () => gtm.tagClickPromoCard({ label: promo.title || promo.gtm_title });

  return (
    <div className="promo-card" style={{ backgroundColor: promo.background_colour || 'white' }}>
      <SSRLink
        linkUrl={promo.link}
        linkType={promo.link_type}
        onClick={handleClick}
        gtmPosition={String(order)}
      >
        <img className="promo-card-image" src={promo.image.url} alt="promo" />
        <div className="button promo-card-button">{promo.button_title}</div>
      </SSRLink>
    </div>
  );
};

PromoCard.propTypes = {
  promo: PropTypes.object.isRequired,
};

export default PromoCard;
